import axios from "axios";
import { Dispatch } from "redux";

interface IPostRegistrationAction {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  recaptcha_token: string;
}

export const postRegistrationAction = (
  props: IPostRegistrationAction,
  nextStep?: () => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const { status, data } = await axios({
        method: "POST",
        url: "/public/api/user/registration",
        data: JSON.stringify(props),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (status === 201) {
        if (nextStep) nextStep();
      }
    } catch (error) {
      console.log(error);
    }
  };
};
