import React, { Fragment } from 'react';

import styles from './_arbitrary_list.module.scss';

/**
 * Фрагмент для создания произвольного списка, с точками.
 */

export const ArbitraryList: React.FC<{ list: string[]; listTitle?: string; }> = ({
  listTitle,
  list
}) => (
  <Fragment>
    {listTitle && (
      <div className={styles.title}>
        {listTitle}
      </div>
    )}
    <ul className={styles.list}>
      {list.map((item) => (
        <li>{item}</li>
      ))}
    </ul>
  </Fragment>
);
