import { Dispatch } from "redux";

import { ArticlesActionTypes, IArticle, IArticleActions } from "../../../types";
import { interceptor } from "../../../utils";

/* Сеттеры для экшена получения списка статей */
function setArticlesSuccess(payload: IArticle[]): IArticleActions {
  return {
    type: ArticlesActionTypes.GET_ARTICLES_SUCCESS,
    payload,
  };
}

function setArticlesRequest(payload: boolean): IArticleActions {
  return {
    type: ArticlesActionTypes.GET_ARTICLES_REQUEST,
    payload,
  };
}

function setArticlesError(payload: string | null): IArticleActions {
  return {
    type: ArticlesActionTypes.GET_ARTICLES_ERROR,
    payload,
  };
}

/* Экшен для запроса списка новостей */
const getArticles = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setArticlesRequest(true));
    try {
      const { status, data } = await interceptor.get("/public/api/article/");

      if (status === 200) {
        dispatch(setArticlesError(null));
        dispatch(setArticlesSuccess(data.articles));
      }
    } catch (error) {
      dispatch(setArticlesError("Ошибка получения всех статей"));
      console.log(error);
    }
    dispatch(setArticlesRequest(false));
  };
};

/* Сеттеры для экшена получения отдельной статьи */
function setArticleSuccess(payload: IArticle): IArticleActions {
  return {
    type: ArticlesActionTypes.GET_ARTICLE_SUCCESS,
    payload,
  };
}

function setArticleRequest(payload: boolean): IArticleActions {
  return {
    type: ArticlesActionTypes.GET_ARTICLE_REQUEST,
    payload,
  };
}

function setArticleError(payload: string | null): IArticleActions {
  return {
    type: ArticlesActionTypes.GET_ARTICLE_ERROR,
    payload,
  };
}

/* Экшен для запроса списка новостей */
const getArticle = (articleId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setArticleRequest(true));
    try {
      const { status, data } = await interceptor.get(
        `/public/api/article/${articleId}`
      );

      if (status === 200) {
        dispatch(setArticleError(null));
        dispatch(setArticleSuccess(data.article));
      }
    } catch (error) {
      dispatch(setArticleError("Ошибка получения статей"));
      console.log(error);
    }
    dispatch(setArticleRequest(false));
  };
};

export { getArticle, getArticles };
