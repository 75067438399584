import React from 'react';

import { IIconProps } from '../../../types';

/**
 * Иконка слоев. Принимает на вход:
 *
 * @param {string} className - Внешний класс для стилизации
 * @param {string} height - Высота
 * @param {string} width - Ширина
 * @param {string} fill - Цвет
 * @returns {JSX.Element}
 */

export const Layers: React.FC<IIconProps> = ({
  fill = 'none',
  width = '63',
  height = '60',
  className
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    className={className}
  >
    <path
      d="M62.811 38.7777L62.8044 41.1191L31.5046 59.3085L0 41.1194L0.0066246 38.7781L31.3064 20.5886L62.811 38.7777Z"
      fill="#1AAAFF"/>
    <path d="M62.811 38.7777L62.8044 41.1191L31.5046 59.3086L31.5111 56.9673L62.811 38.7777Z" fill="#57565C"/>
    <path d="M31.5111 56.9673L31.5046 59.3086L0 41.1194L0.0066246 38.7781L31.5111 56.9673Z" fill="#57565C"/>
    <path
      d="M62.811 28.4835L62.8044 30.8248L31.5046 49.0144L0 30.825L0.0066246 28.4837L31.3064 10.2943L62.811 28.4835Z"
      fill="#147FFF"/>
    <path d="M62.811 28.4834L62.8044 30.8247L31.5046 49.0143L31.5111 46.673L62.811 28.4834Z" fill="#57565C"/>
    <path d="M31.5111 46.6729L31.5046 49.0143L0 30.8249L0.0066246 28.4836L31.5111 46.6729Z" fill="#57565C"/>
    <path d="M62.811 18.1891L62.8044 20.5304L31.5046 38.7199L0 20.5309L0.0066246 18.1893L31.3064 0L62.811 18.1891Z"
          fill="#1069EE"/>
    <path d="M62.811 18.1891L62.8044 20.5304L31.5046 38.7199L31.5111 36.3786L62.811 18.1891Z" fill="#57565C"/>
    <path d="M31.5111 36.3786L31.5046 38.7199L0 20.5309L0.0066246 18.1893L31.5111 36.3786Z" fill="#57565C"/>
  </svg>
);
