import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { PaymentReceipt } from "../../components";
import { Preloader } from "../../components/layouts/_Preloader.layout";
import { reCaptchaToken } from "../../constants";
import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import styles from "./_payment_service_page.module.scss";

/**
 * Страница с формой оплаты.
 */

export const PaymentServicePage: React.FC = () => {
  const { id: paramId } = useParams<{ id: string }>();
  const {
    paymentService,
    paymentServiceRequest,
    paymentCommission,
    paymentDetails,
    toPayRequest,
  } = useTypedSelectorHook(({ paymentCategories }) => paymentCategories);
  const { token } = useTypedSelectorHook(({ user }) => user);
  const {
    getPaymentService,
    getCommissionPayment,
    postValidateRequisite,
    postValidateRequisitePrivate,
    postToPayPrivate,
    postToPay,
  } = useActionsHook();
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const [serviceFormValid, setServiceFormValid] = useState(true);
  const [showCheckedTable, setShowCheckedTable] = useState(false);
  const [reCaptcha, setReCaptcha] = useState("");
  const [paymentData, setPaymentData] = useState<{
    account: string;
    amount: string;
    comment: string;
    commission: number;
    commissionAmount: number;
    commissionType: string;
    commissionTypeInfo: string;
    email: string;
    totalAmount: number;
    totalPayable: number;
  }>();
  const [isLogin, setIsLogin] = useState(false);
  const inputAccount = watch("account");
  const inputAmount = watch("amount");
  const inputEmail = watch("email");

  useEffect(() => {
    if (typeof paramId !== "undefined") {
      getPaymentService(paramId);
    }
  }, []);

  useEffect(() => {
    if (
      paymentService?.service.id &&
      inputAccount &&
      inputAmount &&
      inputAccount.length < 100 &&
      inputAccount !== "" &&
      inputAmount !== ""
    ) {
      getCommissionPayment(paymentService.service.id, inputAmount);
    }
  }, [inputAccount, inputAmount]);

  useEffect(() => {
    setServiceFormValid(true);
    if (
      inputAccount &&
      inputAmount &&
      inputAccount.length < 100 &&
      inputAccount !== "" &&
      inputAmount !== "" &&
      inputEmail !== "" &&
      inputEmail &&
      (reCaptcha || isLogin)
    ) {
      setServiceFormValid(false);
    }
  }, [inputEmail, reCaptcha, inputAmount, inputAccount]);

  const onSubmit = (data): void => {
    if (!isLogin) {
      if (errors && paymentService?.service.id) {
        setServiceFormValid(true);
        postValidateRequisite({
          serviceId: paymentService?.service.id,
          recaptchaToken: reCaptcha,
          account: data.account,
          amount: data.amount,
          callback: () => {
            setShowCheckedTable(true);
            setPaymentData({
              ...data,
              ...paymentCommission,
            });
          },
        });
      }
    } else {
      if (errors && paymentService?.service.id) {
        setServiceFormValid(true);
        postValidateRequisitePrivate({
          serviceId: paymentService?.service.id,
          account: data.account,
          amount: data.amount,
          callback: () => {
            setShowCheckedTable(true);
            setPaymentData({
              ...data,
              ...paymentCommission,
            });
          },
        });
      }
    }
  };

  const toPay = (): void => {
    if (!isLogin) {
      if (paymentData && paymentDetails && paymentService?.service.id) {
        postToPay(
          {
            account: paymentData.account,
            amount: paymentData.amount,
            comment: paymentData.comment,
            email: paymentData.email,
            cardType: "VISA",
            jwtToken: paymentDetails?.token,
          },
          paymentService?.service.id
        );
      }
    } else {
      if (paymentData && paymentDetails && paymentService?.service.id) {
        postToPayPrivate(
          {
            account: paymentData.account,
            amount: paymentData.amount,
            comment: paymentData.comment,
            email: paymentData.email,
            cardType: "VISA",
          },
          paymentService?.service.id
        );
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [token]);

  if (showCheckedTable) {
    return (
      <div style={{ margin: "5.625rem 0 7.120rem" }}>
        <Container>
          <Row>
            <Col xxl={12}>
              <div className={styles.title}>
                Убедитесь в правильности реквизитов
              </div>
            </Col>
          </Row>
          <Row className="justify-content-xxl-center">
            <Col xxl={8}>
              <PaymentReceipt
                toBack={paymentService?.serviceCategory.id}
                toPayRequest={toPayRequest}
                paymentData={paymentData}
                paymentDetails={paymentDetails}
                action={() => toPay()}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "2rem 0 7.120rem",
      }}
    >
      <Container>
        <Row>
          <Col xxl={12}>
            <div className={styles.title}>Заполните данные</div>
          </Col>
        </Row>
        <Row className="justify-content-xxl-center">
          <Col xxl={8}>
            <Preloader isLoad={paymentServiceRequest}>
              <form
                className={styles.form_container}
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
              >
                <div className={styles.form_content}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={styles.text}>
                      Реквизиты <span className={styles.required}>*</span>
                    </Form.Label>
                    <Controller
                      name="account"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          className={styles.input}
                          placeholder="Реквизиты"
                          isInvalid={errors.account}
                          onChange={(text) => {
                            if (text.target.value.length < 100) {
                              return onChange(
                                text.target.value.replace(/[^0-9]/g, "")
                              );
                            }
                          }}
                          value={value}
                          ref={ref}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={styles.text}>
                      Сумма зачисления{" "}
                      <span className={styles.required}>*</span>
                    </Form.Label>
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: true,
                        min: paymentService?.service.minPaymentAmount,
                        max: paymentService?.service.maxPaymentAmount,
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          className={styles.input}
                          placeholder="0,00 СОМ"
                          isInvalid={errors.amount}
                          onChange={(text) => {
                            if (text.target.value.length < 7) {
                              onChange(
                                text.target.value.replace(/[^0-9]/g, "")
                              );
                            }
                          }}
                          value={value}
                          ref={ref}
                        />
                      )}
                    />
                    <Form.Text className="text-muted">
                      от {paymentService?.service.minPaymentAmount} KGS до{" "}
                      {paymentService?.service.maxPaymentAmount} KGS
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={styles.text}>
                      E-mail <span className={styles.required}>*</span>
                    </Form.Label>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Form.Control
                          type="email"
                          autoComplete="off"
                          className={styles.input}
                          placeholder="E-mail"
                          isInvalid={errors.email}
                          onChange={onChange}
                          value={value}
                          ref={ref}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className={styles.text}>Комментарий</Form.Label>
                    <Controller
                      name="comment"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <Form.Control
                          as="textarea"
                          rows={3}
                          autoComplete="off"
                          onChange={onChange}
                          value={value}
                          ref={ref}
                        />
                      )}
                    />
                  </Form.Group>

                  {paymentService?.service.id &&
                    inputAccount &&
                    inputAmount &&
                    inputAccount.length < 100 &&
                    inputAccount !== "" &&
                    inputAmount !== "" && (
                      <ul className={styles.form_footer}>
                        {/* <li className={styles.total_title}>Реквизит: <span>Абонент найден</span></li>*/}
                        <li className={styles.total_title}>
                          Комиссия:{" "}
                          {paymentCommission && (
                            <span>
                              {paymentCommission?.commission +
                                " " +
                                paymentCommission?.commissionTypeInfo}{" "}
                              ({paymentCommission?.commissionAmount} KGS)
                            </span>
                          )}
                        </li>
                        <li className={styles.total_title}>
                          Итого к зачислению:{" "}
                          {paymentCommission && (
                            <span>{paymentCommission?.totalAmount} KGS</span>
                          )}
                        </li>
                        <li className={styles.total_title}>
                          Итого к оплате:{" "}
                          {paymentCommission && (
                            <span>{paymentCommission?.totalPayable} KGS</span>
                          )}
                        </li>
                      </ul>
                    )}
                </div>
                {!isLogin && (
                  <ReCAPTCHA
                    sitekey={reCaptchaToken}
                    onChange={(value) => setReCaptcha(value)}
                  />
                )}
                <Button
                  type="submit"
                  className={styles.button}
                  disabled={serviceFormValid}
                  style={{ marginTop: "13px" }}
                >
                  Продолжить
                </Button>
              </form>
            </Preloader>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
