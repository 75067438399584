import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_services_page.module.scss';
import { ArbitraryList } from '../ArbitraryList';

/**
 * Страниц услуг (Прием и выдача международной ускоренной почты (EMS)).
 */

export const InternationalExpeditedMail: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием и выдача международной ускоренной почты (EMS)</div>
        <div className={styles.small_title}>Международные посылки принимаются в открытом виде с проверкой вложения.</div>
        <div className={styles.paragraph}>
          Оцениваются посылки в соответствии с реальной стоимостью вложения.
        </div>
        <div className={styles.paragraph}>
          <span>К СВЕДЕНИЮ ОТПРАВИТЕЛЕЙ МЕЖДУНАРОДНЫХ ПОЧТОВЫХ ОТПРАВЛЕНИЙ</span>
        </div>
        <div className={styles.paragraph}>
          В соответствии с требованиями Всемирной почтовой конвенции и его исполнительного регламента,
          стандарты международного письма:
        </div>
        <ArbitraryList list={[
          'минимальные размеры 90х140 мм;',
          'максимальные размеры 120х235 мм;',
          'максимальная толщина 5 мм.'
        ]}/>
        <br/>
        <div className={styles.paragraph}>
          <span>Почтовых карточек:</span>
        </div>
        <ArbitraryList list={[
          'минимальный размер 90х140 мм;',
          'максимальный размер 105х148 мм.',
        ]}/>
        <br/>
        <div className={styles.paragraph}>
          <span>ПОРЯДОК АДРЕСОВАНИЯ:</span>
        </div>
        <div className={styles.paragraph}>
          <span>
            Адрес следует писать на гладкой стороне конверта, на которой нет закрывающегося клапана и в прямоугольной
            зоне, расположенной на минимальном расстоянии:
          </span>
        </div>
        <ArbitraryList list={[
          'от верхнего края конверта — 40 мм;',
          'от правого бокового края — 15 мм;',
          'от нижнего края — 15 мм.'
        ]}/>
        <br/>
        <div className={styles.paragraph}>
          Адрес отправителя должен размещаться в левом верхнем углу, адрес получателя в правой стороне.
        </div>
        <div className={styles.paragraph}>
          <span>
            Адрес получателя пишется латинскими буквами, индекс, номер дома, квартиры — арабскими цифрами
            в следующей последовательности:
          </span>
        </div>
        <ArbitraryList list={[
          'фамилия адресата, улица, номер дома, квартиры (при необходимости) или почтового ящика;',
          'город, штат и почтовый индекс страны назначения;',
          'наименование страны назначения повторяется на русском языке.'
        ]}/>
        <br/>
        <div className={styles.paragraph}>
          За неправильно указанный адрес предприятие связи ответственность не несет.
        </div>
        <div className={styles.paragraph}>
          Международная письменная корреспонденция, адресованная по не установленному стандарту пересылке, не подлежит.
        </div>
        <div className={styles.paragraph}>
          Конверты для пересылки международной письменной корреспонденции можно приобрести в любом отделении связи.
        </div>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
