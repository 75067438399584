import { useMemo } from 'react';

/**
 * Хук для конкатенации классов CSS. Принимает на вход:
 *
 * @param {object} classes  - ключ: название класса, значение: истинность (активный ли класс).
 * @returns {string} result - строка активных классов
 */

type IClasses = {
  [className: string]: boolean;
};

export function useClassNames(classes: IClasses): string {
  // Todo Egor: Добавить тип
  const generateClassNames = (classes: IClasses) => {
    const $finalClassNames: Array<string> = [];

    Object.entries(classes).forEach(([className, active]) => {
      if (active) $finalClassNames.push(className);
    });

    return $finalClassNames.join(' ');
  };

  return useMemo(() => generateClassNames(classes), [classes]);
}
