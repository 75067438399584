import { Col, Container, Row } from "react-bootstrap";

import React from "react";
import styles from "../../_press_center.module.scss";

/**
 * Фрагмент c достижениями, для страницы Пресс центр.
 */

export const Questions: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Часто задаваемые вопросы</div>
        <div className={styles.paragraph}>
          Государственное предприятие «Кыргыз почтасы» взаимодействует с
          представителями средств массовой информации (СМИ) для актуального
          информирования о работе предприятия, новых услугах, достижениях,
          проводимых мероприятиях. ГП «Кыргыз почтасы» готовы обеспечить
          свободный доступ СМИ к информации о почте, оперативно помочь
          организовать интервью и ответить на ваши вопросы.
          <br />
          Пресс-секретарь
          <br />
          Раимкулова Назгуль Талайбековна
          <br />
          +996 312 61 00 69 e-mail:
          <br />
          nraimkylova@kyrgyzpost.kg
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Col>
    </Row>
  </Container>
);
