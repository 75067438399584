import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import {
  servicesPageListAdministrativeOffenses,
  servicesPageListBankServices,
  servicesPageListCellularCommunication,
  servicesPagePaymentsPopulation
} from '../../../../constants';
import styles from '../../_services_page.module.scss';
import { ArbitraryList } from '../ArbitraryList';

/**
 * Страниц услуг (Прием платежей).
 */

export const AcceptingPayments: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием платежей от населения</div>
        <div className={styles.small_title}>1.Прием платежей за государственные услуги:</div>
        <div className={styles.small_title}>2.Прием платежей различных видов налогов:</div>
        <div className={styles.small_title}><span>— Налоги:</span></div>
        <div className={styles.paragraph}>Налог на движимое имущество 4 группы (автотранспорт);</div>
        <div className={styles.small_title}>3.Прием платежей за коммунальные услуги:</div>
        <div className={styles.paragraph}><span>Прием платежей от населения за:</span></div>
        {servicesPagePaymentsPopulation.map(({ title, list }) => (
          <ArbitraryList list={list} listTitle={title}/>
        ))}
        <div className={styles.small_title}>4.Прием платежей за услуги сотовых операторов:</div>
        {servicesPageListCellularCommunication.map(({ title, list }) => (
          <ArbitraryList list={list} listTitle={title}/>
        ))}
        <div className={styles.small_title}>5.Прием платежей за административные правонарушения:</div>
        {servicesPageListAdministrativeOffenses.map(({ title, list }) => (
          <ArbitraryList list={list} listTitle={title}/>
        ))}
        <div className={styles.small_title}>6.Услуги Банка:</div>
        {servicesPageListBankServices.map(({ title, list }) => (
          <ArbitraryList list={list} listTitle={title}/>
        ))}
        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
