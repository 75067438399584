enum ArticlesActionTypes {
  GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS",
  GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST",
  GET_ARTICLES_ERROR = "GET_ARTICLES_ERROR",
  GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS",
  GET_ARTICLE_REQUEST = "GET_ARTICLE_REQUEST",
  GET_ARTICLE_ERROR = "GET_ARTICLE_ERROR",
}

/* Типы объекта статьи */
interface IArticle {
  id: string;
  title: string;
  description: string;
  content: string;
  img: string;
  created_at: string;
  updated_at: string;
}

/* Сеттеры для списка всех статей */
interface ISetArticles {
  type: ArticlesActionTypes.GET_ARTICLES_SUCCESS;
  payload: IArticle[];
}

interface ISetArticlesRequest {
  type: ArticlesActionTypes.GET_ARTICLES_REQUEST;
  payload: boolean;
}

interface ISetArticlesError {
  type: ArticlesActionTypes.GET_ARTICLES_ERROR;
  payload: string | null;
}

/* Сеттеры для отдельной статьи */
interface ISetArticle {
  type: ArticlesActionTypes.GET_ARTICLE_SUCCESS;
  payload: IArticle;
}

interface ISetArticleRequest {
  type: ArticlesActionTypes.GET_ARTICLE_REQUEST;
  payload: boolean;
}

interface ISetArticleError {
  type: ArticlesActionTypes.GET_ARTICLE_ERROR;
  payload: string | null;
}

type IArticleActions =
  | ISetArticles
  | ISetArticlesRequest
  | ISetArticlesError
  | ISetArticle
  | ISetArticleRequest
  | ISetArticleError;

export { ArticlesActionTypes };
export type { IArticle, IArticleActions };
