import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { IconButton, Search } from "../../../atoms";
import style from "./_search_form.module.scss";

interface ISearchForm {
  sendingEvent: (trackNumber) => void;
  placeholder?: string;
  className?: string;
}

/**
 * Форма поиска.
 *
 * @param sending
 * @param Event
 * @param {string} placeholder - Подпись для поля ввода
 * @param {string} className - Внешний класс для стелизации общего контейнера
 */

export const SearchForm: React.FC<ISearchForm> = ({
  sendingEvent,
  placeholder,
  className,
}) => {
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const trackNumber = watch("trackNumber");

  const onSubmit = ({ trackNumber }): void => {
    if (trackNumber && trackNumber.length > 1) {
      sendingEvent(trackNumber);
    }
  };

  useEffect(() => {
    if (trackNumber && trackNumber.length > 1) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  }, [trackNumber]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
      <div className={`${style.search_form} ${className}`}>
        <Controller
          name="trackNumber"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Введите трек-номер"
              isInvalid={errors.trackNumber}
              onChange={(text) => onChange(
                text.target.value.replace(/[^A-Za-z0-9]/g, "")
              )}
              value={value}
              ref={ref}
            />
          )}
        />
        <IconButton className={style.button} disabled={isDisabledButton}>
          <Search width={20} height={20} />
        </IconButton>
      </div>
    </form>
  );
};
