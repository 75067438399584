export enum TrackingEmailsActionTypes {
  GET_TRACKING_EMAILS_SUCCESS = "GET_TRACKING_EMAILS_SUCCESS",
  GET_TRACKING_EMAILS_REQUEST = "GET_TRACKING_EMAILS_REQUEST",
  SET_TRACKING_EMAILS_ERROR = "SET_TRACKING_EMAILS_ERROR",
}

export interface ITrackingEmailsProps {
  EventName: string;
  date: string;
}

export interface ISetTrackingEmails {
  type: TrackingEmailsActionTypes.GET_TRACKING_EMAILS_SUCCESS;
  payload: ITrackingEmailsProps[];
}

export interface ISetTrackingEmailsRequest {
  type: TrackingEmailsActionTypes.GET_TRACKING_EMAILS_REQUEST;
  payload: boolean;
}

export interface ISetTrackingEmailsError {
  type: TrackingEmailsActionTypes.SET_TRACKING_EMAILS_ERROR;
  payload: any;
}

export type ITrackingEmails = ISetTrackingEmails | ISetTrackingEmailsRequest | ISetTrackingEmailsError;
