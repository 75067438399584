import React from "react";
import { Container } from "react-bootstrap";

export const TransferCardCash: React.FC = () => (
  <Container>
    <div className="col-inner">
      <div>
        <iframe
          id="p2pFrame"
          src="https://widget3.intervale.ru/?portal_id=KYRGYZPOSTWIDGET1195F974A3F17D1C&amp;page=card2cash_kg_post#"
          frameBorder="0"
          scrolling="no"
          height="1500"
          width="100%"
        />
      </div>
    </div>
  </Container>
);
