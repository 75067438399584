import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';

import { LinkButton } from '../../../../components';
import {
  servicesPageArbitraryLists,
  servicesPageMoneyTransfersLinks,
  servicesPageTableInternationalTransfers,
  servicesPageTableMoneyTransferSystems
} from '../../../../constants';
import styles from '../../_services_page.module.scss';
import { ArbitraryList } from '../ArbitraryList';

/**
 * Страница (Прием и выдача электронных денежных переводов).
 */

export const MoneyTransfers: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием и выдача электронных денежных переводов</div>
        <div className={styles.paragraph}>
          Национальная системы денежных переводов ОАО «Кыргыз почтасы» — система моментальных денежных переводов.
          Система работает на рынке денежных переводов без открытия счета и предоставляет услуги на территории
          Кыргызской Республики и стран СНГ.
        </div>
        <div className={styles.paragraph}>
          Обмен международными денежными переводами осуществляется с 7-ю странами СНГ:
        </div>

        <Table responsive className={styles.table}>
          <thead>
          <tr>
            <th>Наименование страны</th>
            <th>Предельная сумма одного перевода</th>
          </tr>
          </thead>
          <tbody>
          {servicesPageTableInternationalTransfers.map(({ td1, td2 }) => (
            <tr key={td1}>
              <td>{td1}</td>
              <td>{td2}</td>
            </tr>
          ))}
          </tbody>
        </Table>

        <div className={styles.paragraph}>
          Удобство системы заключается в том, что клиенты имеют возможность быстро, и безопасно перевести денежные
          средства в любой филиал или отделение связи по месту жительства получателя, а это 920 точек расположенные
          на территории всей республики.
        </div>
        <div className={styles.paragraph}>
          Для удобства клиентам мы предоставляем 2 вида внутренних переводов:
        </div>
        <div className={styles.paragraph}>
          <span>— адресный перевод</span> – в этом случае отправитель должен указать точный адрес получателя, который
          будет оплачен в отделении связи по месту жительства. Также предусмотрена услуга доставки перевода на дом.
        </div>
        <div className={styles.paragraph}>
          <span>— безадресный перевод</span> – в этом случае после отправки перевода, отправитель должен будет
          сообщить получателю номер и код перевода, который будет оплачен в любом автоматизированном филиале
          или отделении связи по желанию клиента. При этом получателю необходимо знать номер и кодовое слово перевода.
        </div>
        {servicesPageArbitraryLists.map(({ title, list }) => (
          <ArbitraryList list={list} listTitle={title}/>
        ))}

        <Table responsive className={styles.table}>
          <thead>
          <tr>
            <th colSpan={2}>
              Тарифы на пересылку адресных денежных переводов в пределах Кыргызской Республики
            </th>
            <th colSpan={2}>
              Тарифы на пересылку срочных безадресных денежных переводов в пределах Кыргызской Республики
            </th>
            <th colSpan={2}>
              Тарифы на пересылку денежных переводов за пределы Кыргызской Республики (международные)
            </th>
          </tr>
          <tr>
            <th>Размер пересылаемой суммы</th>
            <th>Тариф</th>
            <th>Размер пересылаемой суммы</th>
            <th>Тариф</th>
            <th>Размер пересылаемой суммы</th>
            <th>Тариф</th>
          </tr>
          </thead>
          <tbody>
          {servicesPageTableMoneyTransferSystems.map(({ td1, td2, td3, td4, td5, td6 }) => (
            <tr key={td1}>
              <td width={200}>{td1}</td>
              <td width={60}>{td2}</td>
              <td width={200}>{td3}</td>
              <td width={60}>{td4}</td>
              <td width={200}>{td5}</td>
              <td width={60}>{td6}</td>
            </tr>
          ))}
          </tbody>
        </Table>

        <div className={styles.small_title}>Прием денежных переводов производится в национальной валюте</div>
        <div className={styles.paragraph}>
          Перечень лиц, причастных к террористической и экстремистской деятельности или распространению оружия
          массово уничтожения:
        </div>
        {servicesPageMoneyTransfersLinks.map(({ title, href }) => (
          <LinkButton
            link={href}
            ext={true}
            children={title}
            className={styles.link}
          />
        ))}
        <br/>
        <br/>
      </Col>
    </Row>
  </Container>
);
