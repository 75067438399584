import React, { Fragment } from "react";

import { Spinner } from "../atoms";
import styles from "./_preloader.module.scss";

interface IPreloader {
  children: JSX.Element | JSX.Element[];
  isLoad: boolean;
}

/**
 * Прелоадер. Принимает на вход:
 *
 * @param {JSX.Element | JSX.Element[]} children - дочерние компоненты
 * @param {boolean} isLoad - статус загрузки
 * @returns {JSX.Element | JSX.Element[]}
 */

export const Preloader: React.FC<IPreloader> = ({ children, isLoad }) => {
  if (isLoad) {
    return <Spinner className={styles.preloader} />;
  }

  return <Fragment>{children}</Fragment>;
};
