import React from "react";
import { NavLink } from "react-router-dom";

import { useClassNames } from "../../../utils/hooks";
import styles from "./_link_button.module.scss";

interface ILinkButton {
  className?: string;
  children: React.ReactNode;
  activeClassName?: string;
  link: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  ext?: boolean;
  style?: any;
}

/**
 * Атом внешней ссылки или внутренней ссылки. Принимает на вход: (Перефразируйте)
 *
 * @param {string} className - внешний класс для стилизации
 * @param {React.ReactNode} children - дочерние элементы
 * @param {string} activeClassName - класс для NavLink с активными стилями
 * @param {boolean} ext - статус показывающий ввдет ли ссылка на внешнюю страницу
 * @param {string} link - ссылка на другую страницу или роут
 * @param {React.MouseEventHandler<HTMLAnchorElement>} onClick - внешние событие нажатия
 * @returns {JSX.Element | JSX.Element[]}
 */

export const LinkButton: React.FC<ILinkButton> = ({
  className = "",
  children,
  activeClassName = "",
  link,
  ext = false,
  onClick = () => {},
}) => {
  const disabledClass = useClassNames({
    // [styles.disabled]: link.length < 2,
    [className]: true,
  });

  return ext ? (
    <a
      className={`${styles.link_button} ${className} ${disabledClass}`}
      href={link}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <NavLink
      className={`${styles.link_button} ${className} ${disabledClass}`}
      activeClassName={activeClassName}
      to={link}
    >
      {children}
    </NavLink>
  );
};
