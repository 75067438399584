import React, { useEffect } from "react";

import { News, ParcelTracking } from "../../components";
import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import { NewsCarousel, Services } from "./fragments/";

export const HomePage: React.FC = () => {
  const { getArticles } = useActionsHook();
  const { articles, articlesRequest } = useTypedSelectorHook(
    ({ articles }) => articles
  );

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <React.Fragment>
      {articles && <NewsCarousel articles={articles.slice(0, 6)} />}
      <ParcelTracking redirect={true} />
      <Services />
      {/* <Business/>*/}
      {/* <BannerMail className={styles.banner_mail}/>*/}
      {articles && (
        <News
          articles={articles.slice(0, 6)}
          articlesRequest={articlesRequest}
        />
      )}
    </React.Fragment>
  );
};
