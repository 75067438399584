import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { IArticle } from "../../../types";
import { LinkButton } from "../../atoms";
import style from "./_news.module.scss";

interface INews {
  articles: IArticle[] | null;
  articlesRequest: boolean;
  showLink?: boolean;
}

/**
 * Фрагмент: "Последние новости".
 */
export const News: FC<INews> = ({
  articles,
  articlesRequest,
  showLink = true,
}) => {
  if (!articles || articlesRequest) return null;

  return (
    <Container className={style.wrapper}>
      <Row>
        <Col xxl={12}>
          <div className={style.header}>
            <div className={style.title}>Новости</div>
            {showLink && (
              <LinkButton
                link="/articles"
                className={style.link}
                children="Все новости"
              />
            )}
          </div>
        </Col>
        {articles.map(({ id, title, description, img, created_at }) => (
          <Col xxl={4} key={id}>
            <div className={style.card}>
              <LinkButton link={`/news/${id}`}>
                <div>
                  <div className={style.card_title}>{title}</div>
                  <div className={style.card_sub_title}>{created_at}</div>
                </div>
                <div className={style.card_description}>{description}</div>
              </LinkButton>
              <img
                className={style.card_image}
                src={`https://pay2.kyrgyzpost.kg/uploads/article/${img}`}
                alt="img"
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
