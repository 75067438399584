import React, { useEffect } from "react";
import { Col, Container, ListGroup, Row, Table } from "react-bootstrap";
import { Preloader } from "src/components/layouts/_Preloader.layout";

import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import styles from "../PaymentsPage/_payments_page.module.scss";

export const PaymentHistoryPage: React.FC = () => {
  const { getProfilePaymentAction } = useActionsHook();
  const { paymentHistory, paymentHistoryRequest } = useTypedSelectorHook(
    ({ profile }) => profile
  );

  const dateFc = (date): string => {
    return new Date(date).toISOString();
  };

  useEffect(() => {
    getProfilePaymentAction();
  }, []);

  return (
    <div className={styles.services}>
      <Container>
        <Row>
          <Col xxl={12}>
            <div className={styles.title}>История платежей</div>
          </Col>
        </Row>
        <Row>
          <Preloader isLoad={paymentHistoryRequest}>
            <Table striped>
              <thead style={{ borderBottom: "2px solid #000" }}>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Сумма</th>
                  <th>Валюта</th>
                  <th>Почта</th>
                  <th>Статус</th>
                  <th>Дата</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.length ? (
                  paymentHistory.map(
                    (
                      {
                        service_name,
                        total_amount,
                        total_payable,
                        commission_amount,
                        currency,
                        email,
                        status_name,
                        created_at,
                      },
                      index
                    ) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{service_name}</td>
                        <td>
                          <ListGroup variant="flush">
                            <ListGroup.Item
                              style={{ background: "transparent" }}
                            >
                              Сумма: {total_payable}
                            </ListGroup.Item>
                            <ListGroup.Item
                              style={{ background: "transparent" }}
                            >
                              Коммисия: {commission_amount}
                            </ListGroup.Item>
                            <ListGroup.Item
                              style={{ background: "transparent" }}
                            >
                              Итого к зачеслению: {total_amount}
                            </ListGroup.Item>
                            <ListGroup.Item
                              style={{ background: "transparent" }}
                            >
                              Итого к оплате: {total_payable}
                            </ListGroup.Item>
                          </ListGroup>
                        </td>
                        <td>{currency}</td>
                        <td>{email}</td>
                        <td>{status_name}</td>
                        <td>{dateFc(created_at)}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>Вы не совершали платежи</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Preloader>
        </Row>
      </Container>
    </div>
  );
};
