import axios from "axios";

import { apiBaseUrl } from "../constants";

/**
 * Interceptor для заголовков API запросов, подставляет токен и начальный URL
 */

export const interceptor = axios.create({
  // withCredentials: true,
  baseURL: apiBaseUrl,
});

interceptor.interceptors.request.use((config) => {
  config.headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  return config;
});
