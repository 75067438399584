enum MenuActionTypes {
  GET_MENU_SUCCESS = "GET_MENU_SUCCESS",
  GET_MENU_REQUEST = "GET_MENU_REQUEST",
  GET_MENU_ERROR = "GET_MENU_ERROR",

  GET_ITEM_MENU_SUCCESS = "GET_ITEM_MENU_SUCCESS",
  GET_ITEM_MENU_REQUEST = "GET_ITEM_MENU_REQUEST",
  GET_ITEM_MENU_ERROR = "GET_ITEM_MENU_ERROR",
}

/* Типы объекта меню */
interface IMenuItem {
  id: string;
  name: string;
  link: string;
  sort: number;
  is_static: boolean;
  content: string;
  children: IMenuItem[] | [];
  created_at: string;
  updated_at: string;
}

interface IMenu extends IMenuItem {
  children: IMenuItem[] | [];
}

/* Сеттеры для главного меню */
interface ISetMenu {
  type: MenuActionTypes.GET_MENU_SUCCESS;
  payload: IMenu;
}

interface ISetMenuRequest {
  type: MenuActionTypes.GET_MENU_REQUEST;
  payload: boolean;
}

interface ISetMenuError {
  type: MenuActionTypes.GET_MENU_ERROR;
  payload: string | null;
}

/* Сеттеры получения пункта меню */
interface ISetItemMenu {
  type: MenuActionTypes.GET_ITEM_MENU_SUCCESS;
  payload: IMenu;
}

interface ISetItemMenuRequest {
  type: MenuActionTypes.GET_ITEM_MENU_REQUEST;
  payload: boolean;
}

interface ISetItemMenuError {
  type: MenuActionTypes.GET_ITEM_MENU_ERROR;
  payload: string | null;
}

type IMenuActions =
  | ISetMenu
  | ISetMenuRequest
  | ISetMenuError
  | ISetItemMenu
  | ISetItemMenuRequest
  | ISetItemMenuError;

export { MenuActionTypes };
export type { IMenu, IMenuActions, IMenuItem };
