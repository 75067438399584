import user1 from "../assets/images/about/user_01.jpg";
import user2 from "../assets/images/about/user_02.jpg";
import user3 from "../assets/images/about/user_03.jpg";
import user4 from "../assets/images/about/user_04.jpg";
import user5 from "../assets/images/about/user_05.jpg";

/**
 * Информация для карточек пользователей на странице (Руководители)
 */

export const aboutUsPageGuide: { img: string; name: string; post: string }[] = [
  {
    img: user1,
    name: "ЧЕРИКЧИЕВ МАРАТ ЖОЛДОШБЕКОВИЧ",
    post: 'Председатель Правления ОАО "Кыргыз почтасы"',
  },
  {
    img: user2,
    name: "АБДИЕВ РОМАН ЖАНЫШЕВИЧ",
    post: 'Заместитель Председателя Правления ОАО "Кыргыз почтасы"',
  },
  {
    img: user3,
    name: "ШЕРАЛИЕВ БАЯЛЫ АБАСБЕКОВИЧ",
    post: 'Заместитель Председателя Правления ОАО "Кыргыз почтасы"',
  },
  {
    img: user4,
    name: "Закиров Данияр Адамович",
    post: 'Заместитель Председателя Правления ОАО "Кыргыз почтасы"',
  },
  {
    img: user5,
    name: "Масиянова Уулжан Каныбековна",
    post: 'Заместитель Председателя Правления ОАО "Кыргыз почтасы" ',
  },
];

/**
 * Информация по струтере на странице (Структура)
 */

export const aboutUsPageStructure: string[] = [
  "Отдел почтовых услуг, логистики и подписки",
  "Отдел международного сотрудничества, взаиморасчетов и контроля качества",
  "Сектор почтовой безопасности",
  "Сектор анализа и мониторинга",
  "Отдел финансовых услуг",
  "Сектор денежных переводов",
  "Отдел информационных технологий",
  "Сектор по сопровождению проектов",
  "Сектор человеческих ресурсов",
  "Отдел внутреннего аудита",
  "Отдел правовой работы",
  "Сектор делопроизводства",
  "Отдел бухгалтерского учета и отчетности",
  "Отдел планирования и экономики",
  "Отдел закупок, имущества и хозяйственного обеспечения",
];

/**
 * Информация по филиалам на странице (Структура)
 */

export const aboutUsPageBranches: string[] = [
  "ЦМПОЛ",
  "ГП «Кыргыз маркасы»",
  "Бишкек почтамт",
  "Областные филиалы",
  "Районные Филиалы",
  "Городские Филиалы",
];
