import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { CardCategory, LinkButton } from "../../components";
import { Preloader } from "../../components/layouts/_Preloader.layout";
import { baseUrl } from "../../constants";
import { ICategoryProps } from "../../types";
import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import styles from "./_payments_page.module.scss";

/**
 * Страница с категориями платежей.
 */

export const PaymentsPage: React.FC = () => {
  const { getPaymentCategoriesAction } = useActionsHook();
  const { categories, categoriesRequest } = useTypedSelectorHook(
    ({ paymentCategories }) => paymentCategories
  );
  const [categoriesList, setCategoriesList] = useState<ICategoryProps[]>([]);

  useEffect(() => {
    getPaymentCategoriesAction();
  }, []);

  useEffect(() => {
    if (categories) {
      setCategoriesList(categories);
    }
  }, [categories]);

  return (
    <div className={styles.services}>
      <Container>
        <Row>
          <Col xxl={12}>
            <div className={styles.title}>Оплата услуг</div>
          </Col>
        </Row>
        <Row>
          <Preloader isLoad={categoriesRequest}>
            {categoriesList.map((category) => (
              <Col xxl={3} key={category.id}>
                <LinkButton link={`/payment/${category.id}`}>
                  <CardCategory
                    title={category.name.ru}
                    description={category.description.ru}
                    image={`${baseUrl}${category.img}`}
                    className={styles.card}
                  />
                </LinkButton>
              </Col>
            ))}
          </Preloader>
        </Row>
      </Container>
    </div>
  );
};
