import {
  IActionPaymentCategories,
  IPaymentCategoriesState,
  PaymentCategoriesActionTypes,
  PaymentCategoryActionTypes,
  PaymentServiceActionTypes
} from '../../types';

const initialState: IPaymentCategoriesState = {
  categories: null,
  categoriesRequest: false,
  category: null,
  categoryRequest: false,
  paymentService: null,
  paymentServiceRequest: false,
  paymentCommission: null,
  paymentCommissionRequest: false,
  paymentDetails: null,
  paymentDetailsRequest: false,
  toPayRequest: false,
};

/**
 * Редьюсер категорий платежей
 */

export const paymentCategories = (
  state = initialState,
  action: IActionPaymentCategories
): IPaymentCategoriesState => {
  switch (action.type) {
    case PaymentCategoriesActionTypes.GET_PAYMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload
      };
    case PaymentCategoriesActionTypes.GET_PAYMENT_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesRequest: action.payload
      };
    case PaymentCategoryActionTypes.GET_PAYMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload
      };
    case PaymentCategoryActionTypes.GET_PAYMENT_CATEGORY_REQUEST:
      return {
        ...state,
        categoryRequest: action.payload
      };
    case PaymentServiceActionTypes.GET_PAYMENT_SERVICE_SUCCESS:
      return {
        ...state,
        paymentService: action.payload
      };
    case PaymentServiceActionTypes.GET_PAYMENT_SERVICE_REQUEST:
      return {
        ...state,
        paymentServiceRequest: action.payload
      };
    case PaymentServiceActionTypes.GET_COMMISSION_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentCommission: action.payload
      };
    case PaymentServiceActionTypes.GET_COMMISSION_PAYMENT_REQUEST:
      return {
        ...state,
        paymentCommissionRequest: action.payload
      };
    case PaymentServiceActionTypes.GET_VALIDATE_SUCCESS:
      return {
        ...state,
        paymentDetails: action.payload
      };
    case PaymentServiceActionTypes.GET_VALIDATE_REQUEST:
      return {
        ...state,
        paymentDetailsRequest: action.payload
      };
    case PaymentServiceActionTypes.GET_TO_PAY_REQUEST:
      return {
        ...state,
        toPayRequest: action.payload
      };
    default:
      return state;
  }
};
