import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import {
  aboutUsLinks,
  copyright,
  pressCenterLinks,
  servicesLinks
} from "../../../constants";
import { LinkButton } from "../../atoms";
import styles from "./_footer.module.scss";

/**
 * Футер.
 */

export const Footer: React.FC = () => (
  <React.Fragment>
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.title}>О нас</div>
            <div className={styles.content}>
              {aboutUsLinks.map(({ href, text, sideLink }) => !sideLink ? (
                <LinkButton link={href} className={styles.link} key={text}>
                  {text}
                </LinkButton>
              ) : (
                <a href={sideLink} className={styles.link}>{text}</a>
              ))}
            </div>
          </Col>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.title}>Услуги</div>
            <div className={styles.content}>
              {servicesLinks.map(({ href, sideLink, text }) => !sideLink ? (
                <LinkButton link={href} className={styles.link} key={text}>
                  {text}
                </LinkButton>
              ) : (
                <a href={sideLink} className={styles.link}>{text}</a>
              ))}
            </div>
          </Col>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.title}>Пресс-центр</div>
            <div className={styles.content}>
              {pressCenterLinks.map(({ href, sideLink, text }) => !sideLink ? (
                <LinkButton link={href} className={styles.link} key={text}>
                  {text}
                </LinkButton>
              ) : (
                <a href={sideLink} className={styles.link}>{text}</a>
              ))}
            </div>
          </Col>
          <Col xs={5} md={3} xxl={3}>
            {/* <div className={styles.title}>Тарифы</div>*/}
            {/* <div className={styles.content}>*/}
            {/*  {pricingPlans.map(({ href, sideLink, text }) => !sideLink ? (*/}
            {/*    <LinkButton link={href} className={styles.link} key={text}>*/}
            {/*      {text}*/}
            {/*    </LinkButton>*/}
            {/*  ) : (*/}
            {/*    <a href={sideLink} className={styles.link}>{text}</a>*/}
            {/*  ))}*/}
            {/* </div>*/}
          </Col>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.sub_title}>Адрес</div>
            <div className={styles.info}>720001, Кыргызская Республика,</div>
            <div className={styles.info}>г. Бишкек, пр. Чуй, 227</div>
          </Col>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.sub_title}>Телефоны доверия</div>
            <div className={styles.info_number}>+996(312) 61 52 94</div>
            <div className={styles.info_number}>+996(312) 61 52 96</div>
            <div className={styles.info_number}>приемная: <br/> +996 (312) 61 36 07</div>
            <div className={styles.info_number}>канцелярия: <br/> +996 (312) 61 52 93</div>
          </Col>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.sub_title}>Факс</div>
            <div className={styles.info_number}>+996 (312) 61 21 01</div>
            <div className={styles.info_number}>+996 (312) 90 17 25</div>
          </Col>
          <Col xs={5} md={3} xxl={3}>
            <div className={styles.sub_title}>Электронная почта</div>
            <div className={styles.info}>info@kyrgyzpost.kg</div>
          </Col>
          <div className={styles.copyright}>{copyright}</div>
        </Row>
      </Container>
    </div>
  </React.Fragment>
);
