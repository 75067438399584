import React, { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { LinkButton } from '../../../../components';
import { servicesPageSubscribeDownloadDocks } from '../../../../constants';
import styles from '../../_services_page.module.scss';

/**
 * Страниц услуг (Подписка).
 */

export const Subscription: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Подписка</div>
        <div className={styles.small_title}>
          ВНИМАНИЕ <br/>
          Уважаемые подписчики и работники почтовой связи!
        </div>
        <div className={styles.paragraph}>
          Издания, вошедшие в Каталог, распространяются на территории Кыргызской Республики и государств, заключивших
          соответствующие Договора по подписке на I — полугодие 2021 год.
        </div>
        <div className={styles.small_title}>ОПИСАНИЕ КАТАЛОГА</div>
        <div className={styles.paragraph}>
          I. Газеты и журналы Кыргызской Республики. <br/>
          II. Газеты и журналы других государств.
        </div>
        <div className={styles.paragraph}>
          Издания внутри каждого раздела расположены в алфавитном порядке и имеют подписной индекс. Рядом с названием
          в скобках указаны языки, на которых публикуются материалы. Некоторые издания имеют аннотации
          с указанием телефонов издателей. <br/>
          Могут быть выпущены дополнения к Каталогу.
        </div>
        <div className={styles.small_title}>ПОДПИСНАЯ ЦЕНА</div>
        <div className={styles.paragraph}>
          В Каталоге указаны подписные цены на каждый подписной период. <br/>
          Подписная цена издания, взимается с подписчика при оформлении подписки. Складывается из каталожной цены
          и стоимости местных услуг почтовой связи по доставке, с учетом НДС. <br/>
          1. При оформлении подписки на периодические издания по безналичному расчету (перечисление) НсП не взимается.  <br/>
          2. При оформлении подписки на периодические издания за наличный расчет дополнительно изымается НсП – 2%.  <br/>
          Подписная цена приводится для всех регионов Кыргызской Республики. <br/>
        </div>
        <div className={styles.small_title}>ОФОРМЛЕНИЕ ПОДПИСКИ</div>
        <div className={styles.paragraph}>
          Подписка, на включенные в Каталог газеты и журналы, принимается на срок от 1 до 6 месяцев в соответствии
          с периодичностью выхода в печать. При оформлении подписки подписчик заполняет бланк Абонемента с доставочной
          карточкой ф.СП-1 на каждое издание отдельно ручкой, разборчиво и без сокращений. Наименование и индекс издания,
          срок и стоимость подписки должны соответствовать данным Каталога. На Абонементе и доставочной карточке
          указывается почтовый индекс и полный адрес подписчика. Оплата может производится как за наличные деньги,
          так и по безналичному расчету. В подтверждение оплаты стоимости подписки подписчик получает Абонемент
          с оттиском кассовой машины.
        </div>
        <div className={styles.paragraph}>
          При оформлении подписки в отделении связи, не имеющей кассовой машины, подписчик получает квитанцию ф.
          СП-2 на общую сумму подписки и Абонемент на каждое издание с оттиском календарного штемпеля.
          Прием подписки осуществляется с сентября 2018 года по декабрь 2018 года.
        </div>
        <div className={styles.small_title}>ОТВЕТСТВЕННОСТЬ ПЕРЕД ПОДПИСЧИКАМИ</div>
        <div className={styles.paragraph}>
          Моральную и материальную ответственность перед подписчиками на невыход отдельных номеров, нарушение графика
          выхода, выпуск сдвоенных номеров, ликвидацию изданий несут редакции газет и журналов.
        </div>
        <div className={styles.small_title}>ВНИМАНИЮ РАБОТНИКОВ ПОЧТОВОЙ СВЯЗИ!</div>
        <div className={styles.paragraph}>
          Подписной Каталог должен находиться в доступном для клиента месте в каждом почтовом отделении связи.
          Отсутствие данного Каталога в операционном зале, а также использование рукописных или иных выписок вместо
          него рассматривается как грубое нарушение правил распространения печати по подписке и договоров
          с распространяющими организациями.
        </div>

        <br/><br/>

        {servicesPageSubscribeDownloadDocks.map(({ title, href }) => (
          <Fragment>
            <LinkButton
              link={href}
              ext={true}
              children={title}
              className={styles.link}
            /> <br/><br/>
          </Fragment>
        ))}

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
