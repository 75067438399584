import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import styles from "./_item_button.module.scss";

interface ItemNavigation {
  key: string;
  onClick: (event) => void;
  sideLink?: string;
  path?: string;
  isStatic?: boolean;
  id?: string;
}

export const ItemNavigation: FC<ItemNavigation> = ({
  key,
  path,
  sideLink = "",
  onClick,
  children,
  id,
  isStatic,
}) => {
  if (typeof path !== "undefined" || isStatic) {
    return (
      <NavLink
        className={styles.link_button}
        // activeClassName={activeClassName}
        key={key}
        to={`/dynamic/${id}`}
      >
        {children}
      </NavLink>
    );
  } else if (sideLink?.length > 1) {
    return (
      <a
        key={key}
        href={sideLink}
        className={styles.link_button}
        // target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <div
      className={styles.link_button}
      key={key}
      onClick={(event) => onClick(event)}
    >
      {children}
    </div>
  );
};
