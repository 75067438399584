import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// todo Alibek: иконки нужно оформлять в отдельный компонент в атомах
import icon from '../../../assets/images/icons/icon_receipt_check.svg';
import style from './_index.module.scss';
import { IValidateProps } from '../../../types';

/**
 * Чек об оплате
 */

interface IPaymentReceipt {
  paymentData: any | null,
  paymentDetails: IValidateProps | null,
  toPayRequest: boolean,
  toBack: number | undefined,
  action: () => void
}

export const PaymentReceipt: React.FC<IPaymentReceipt> = ({
  toPayRequest,
  paymentDetails,
  toBack,
  paymentData,
  action
}) => (
  <div className={style.content}>
    <div className={style.check}>
      <img src={icon} alt="svg" className={style.icon}/>
      {paymentData && paymentDetails && (
        <ul className={style.info}>
          <li>Реквизит: <span>{paymentData.account}</span></li>
          <hr/>
          <li>Информация по реквизитам: <span>{paymentDetails.data.details}</span></li>
          <hr/>
          <li>Сумма: <span>{paymentData?.totalPayable}</span></li>
          <hr/>
          <li>Комментарий: <span>{paymentData.comment}</span></li>
          <hr/>
          <li>Почта: <span>{paymentData.email}</span></li>
          <hr/>
          <li>Тип: <span>VISA</span></li>
          <hr/>
          <li>Коммисия: <span>{paymentData?.commissionAmount}</span></li>
          <hr/>
          <li>Итого к зачеслению: <span>{paymentData?.totalAmount}</span></li>
          <hr/>
          <li>Итого к оплате: <span>{paymentData?.totalPayable}</span></li>
        </ul>
      )}
    </div>

    <div className={style.footer}>
      <Button variant="danger">
        <Link to={`/payment/${toBack}`} style={{ color: '#FFF' }}>Отмена</Link>
      </Button>
      <Button variant="primary" onClick={action} disabled={toPayRequest}>
        {toPayRequest ? 'Подождите' : 'Оплатить'}
      </Button>
    </div>
  </div>
);

