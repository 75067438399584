import axios from "axios";
import { Dispatch } from "redux";

import { apiBaseUrl } from "../../../constants";
import { TrackingEmailsActionTypes } from "../../../types";

/**
 *  Выполняет запрос на получение маршрута по доставке письма
 */
export const getTrackingEmailAction = (barcode: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: TrackingEmailsActionTypes.GET_TRACKING_EMAILS_REQUEST,
      payload: true
    });
    try {
      const { status, data } = await axios.get(
        `${apiBaseUrl}/public/api/barcode/${barcode}`
      );

      if (status === 200) {
        dispatch({
          type: TrackingEmailsActionTypes.GET_TRACKING_EMAILS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: TrackingEmailsActionTypes.SET_TRACKING_EMAILS_ERROR,
      //   payload: error
      // });
    }
    dispatch({
      type: TrackingEmailsActionTypes.GET_TRACKING_EMAILS_REQUEST,
      payload: false
    });
  };
};
