import { IMenu, IMenuActions, MenuActionTypes } from "../../types";

interface IInitialState {
  mainMenu: IMenu | [];
  mainMenuRequest: boolean;
  mainMenuError: string | null;

  itemMainMenu: IMenu | null;
  itemMainMenuRequest: boolean;
  itemMainMenuError: string | null;
}

const initialState: IInitialState = {
  mainMenu: [],
  mainMenuRequest: false,
  mainMenuError: null,

  itemMainMenu: null,
  itemMainMenuRequest: false,
  itemMainMenuError: null,
};

/* Редьюсер главного меню */
const menuReducer = (
  state = initialState,
  action: IMenuActions
): IInitialState => {
  switch (action.type) {
    case MenuActionTypes.GET_MENU_SUCCESS: {
      return {
        ...state,
        mainMenu: action.payload,
      };
    }
    case MenuActionTypes.GET_MENU_REQUEST: {
      return {
        ...state,
        mainMenuRequest: action.payload,
      };
    }
    case MenuActionTypes.GET_MENU_ERROR: {
      return {
        ...state,
        mainMenuError: action.payload,
      };
    }

    case MenuActionTypes.GET_ITEM_MENU_SUCCESS: {
      return {
        ...state,
        itemMainMenu: action.payload,
      };
    }
    case MenuActionTypes.GET_ITEM_MENU_REQUEST: {
      return {
        ...state,
        itemMainMenuRequest: action.payload,
      };
    }
    case MenuActionTypes.GET_ITEM_MENU_ERROR: {
      return {
        ...state,
        itemMainMenuError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { menuReducer };
