import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { aboutUsPageGuide } from '../../../../constants';
import styles from '../../_about.module.scss';

/**
 * Фрагмент с информацией о Руководстве, для страницы О нас.
 */

export const Guide: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Руководство</div>
        <div className={styles.user_cards}>
          {aboutUsPageGuide.map(({ img, name, post }) => (
            <div className={styles.user_card}>
              <img src={img} alt="User image" width="160" height="160"/>
              <div className={styles.paragraph}>
                <span>{name}</span> <br/>
                {post}
              </div>
            </div>
          ))}
        </div>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
