import { IActionUser, LoginActionTypes } from "../../types";

interface IUserReducer {
  token: null | string;
  refreshToken: null | string;
  loginRequest: boolean;
}

const initialState = {
  token: null,
  refreshToken: null,
  loginRequest: false,
};

export const user = (
  state = initialState,
  action: IActionUser
): IUserReducer => {
  switch (action.type) {
    case LoginActionTypes.GET_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case LoginActionTypes.GET_LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: action.payload,
      };
    default:
      return state;
  }
};
