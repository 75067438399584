import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import {
  AcceptingPayments,
  AirTransportation,
  DeliveryLetters,
  DeliveryParcels,
  ExpeditedMail,
  InternationalExpeditedMail,
  MoneyTransfers,
  PackageDelivery,
  Subscription,
  TransferCardCash,
} from "./fragments";

/**
 * Страниц услуг.
 */

export const ServicesPage: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { push } = useHistory();

  switch (id) {
    case "money-transfers":
      return <MoneyTransfers />;
    case "air-transportation":
      return <AirTransportation />;
    case "accepting-payments":
      return <AcceptingPayments />;
    case "delivery-of-letters":
      return <DeliveryLetters />;
    case "package-delivery":
      return <PackageDelivery />;
    case "delivery-parcels":
      return <DeliveryParcels />;
    case "expedited-mail":
      return <ExpeditedMail />;
    case "international-expedited-mail":
      return <InternationalExpeditedMail />;
    case "subscription":
      return <Subscription />;
    case "transfer-from-card-to-cash":
      return <TransferCardCash />;
    default:
      push("/");
      return null;
  }
};
