import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_about.module.scss';

/**
 * Фрагмент с информацией о партнерах, для страницы О нас.
 */

export const Partners: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Партнеры</div>
        <div className={styles.paragraph}>
          ГП «Кыргыз почтасы» — оператор почтовой связи Кыргызской Республики, представляет интересы Кыргызстана
          являясь членом Всемирного почтового союза.
        </div>
        <div className={styles.paragraph}>
          ГП «Кыргыз почтасы» предоставляет широкий спектр почтовых, финансовых, государственных услуг и услуг по приему
          платежей от населения. В распоряжении ГП «Кыргыз почтасы» самая масштабная филиальная сеть в стране,
          охватывающая практически всю территорию Кыргызстана, особенно на уровне районной и сельской местности. Один
          из самых больших трудовых коллективов почтовых работников в республике — почти 4000 сотрудников. Ежегодно
          почтовые работники ГП «Кыргыз почтасы» принимают и доставляют более 3475 тысяч писем, 150 тысяч посылок,
          принимают и оплачивают 160 тысяч денежных переводов.
        </div>
        <div className={styles.paragraph}>
          Почта доставляется во все уголки республики согласно установленному регламенту: 5 раз в неделю до областных
          и районных центров и городов, 3 раза в неделю до отделений связи. Протяженность маршрутов в обе стороны
          составляют свыше 25 тысяч километров.
        </div>
        <div className={styles.paragraph}>
          ГП «Кыргыз почтасы» несет ответственность за обеспечение экономической, социальной, экологической
          стабильности, оказывая общедоступные услуги на всей территории Кыргызской Республики. Осознавая свою
          ответственность за вклад в устойчивое развитие общества, ГП «Кыргыз почтасы» принимает обязательства
          по социально-ответственному взаимодействию со всеми заинтересованными сторонами. Преимущество ГП
          «Кыргыз почтасы» в том, что компания представлена в самых отдаленных населенных пунктах страны,
          и в сложившихся условиях отделения почтовой связи смогут выступить центром экономической активности в селе,
          и для этого руководством ГП «Кыргыз почтасы» прилагаются все усилия.
        </div>
        <div className={styles.paragraph}>
          В компании активно идет процесс модернизации и перехода к качественному предоставлению услуг, что означает
          'не только улучшение обслуживания, но и технического, операционного, технологического, информационного
          обновления почтовой сети. Почта активно работает не только над расширением спектра услуг, оказываемых
          населению, но и над повышением качества. В целях повышения качества почтовых услуг, ГП «Кыргыз почтасы»
          проводит мероприятия, направленные на оптимизацию работы сети почтовой связи, повышение системы подготовки
          и повышения квалификации кадров, повышение социальной защищенности работников.
        </div>
        <div className={styles.paragraph}>
          Всего почта предлагает своим клиентам свыше 55 почтовых, финансовых, инфокоммуникационных и прочих услуг.
          Через почтовые филиалы и отделения связи осуществляется доставка пенсий, пособий и других социальных выплат,
          а также подписных изданий. В отделениях связи можно оплатить коммунальные услуги, оплатить штрафы ГУБДД,
          оплатить плату за проведение технического осмотра автомашин, получить и погасить микрокредиты ОАО
          «Айыл банка», приобрести авиа, железнодорожные и автобусные билеты по всем направлениям, приобрести
          театральные билеты, товары народного потребления. Кроме того, в центрах общественного доступа желающие
          могут выйти в Интернет, отправить и получить электронную почту, распечатать документ, снять ксерокопию,
          произвести оплату за пользование сотовыми телефонами.
        </div>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
