import { combineReducers } from "redux";

import { articlesReducer } from "./_articles.reducer";
import { menuReducer } from "./_menu.reducer";
import { paymentCategories } from "./_paymentCategories.reducer";
import { profile } from "./_profile.reducer";
import { trackingEmails } from "./_trackingEmails.reducer";
import { user } from "./_user.reducer";

export const rootReducer = combineReducers({
  articles: articlesReducer,
  mainMenu: menuReducer,
  trackingEmails,
  paymentCategories,
  profile,
  user,
});

export type RootStateTypes = ReturnType<typeof rootReducer>;
