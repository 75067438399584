import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useActionsHook } from "../utils/hooks";

/**
 * Страница результата после платежа.
 */

export const PaymentStatus: React.FC = () => {
  const { getDownloadReceipt } = useActionsHook();

  function getQueryParams(qs) {
    qs = qs.split("+").join(" ");

    const params = {};
    let tokens;
    const re = /[?&]?([^=]+)=([^&]*)/g;

    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  const status: any = getQueryParams(window.location.hash);

  return (
    <Fragment>
      <Container>
        <Row className="justify-content-xxl-center">
          <Col xxl={8}>
            {status?.status === "succeeded" && (
              <div style={{ margin: "240px auto", textAlign: "center" }}>
                <div
                  style={{
                    border: "1px solid #76D8A3",
                    background: "#F2FFF7",
                    textAlign: "center",
                    paddingTop: "11px",
                    color: "#63bd8c",
                    padding: "20px",
                  }}
                >
                  Оплата прошла успешно
                </div>
                <hr />
                <button
                  onClick={() =>
                    getDownloadReceipt(
                      status["#/status-payment?paymentId"],
                      status.hash
                    )
                  }
                  style={{
                    color: "#1795ff",
                    border: "1px solid transparent",
                    backgroundColor: "transparent",
                  }}
                >
                  Скачать квитанцию
                </button>
              </div>
            )}
            {status?.status === "failed" && (
              <div
                style={{
                  border: "1px solid #DF1E1E",
                  background: "#FFDEDE",
                  margin: "240px auto",
                  textAlign: "center",
                  paddingTop: "11px",
                  color: "#DF1E1E",
                  padding: "20px",
                }}
              >
                Ошибка! Оплата не прошла
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
