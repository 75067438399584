import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import { LinkButton } from "../../components";
import { useActionsHook } from "../../utils/hooks";
import styles from "../PaymentServicePage/_payment_service_page.module.scss";
import style from "./_index.module.scss";

/**
 * Страница авторизации.
 */

export const AuthorizationPage: React.FC = () => {
  const { postLoginAction } = useActionsHook();
  const [registerFormValid, setRegisterFormValid] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  function loginEvent(data): void {
    postLoginAction({ ...data }, () => history.goBack());
    setRegisterFormValid(true);
  }

  return (
    <Container>
      <Row className="justify-content-xxl-center">
        <Col xxl={8}>
          <div className={style.wrapper}>
            <img
              src={logo}
              alt="logo"
              className={style.logo}
              width={220}
              height={60}
            />

            <div className={style.content}>
              <div className={style.title}>Войти</div>

              <Form onSubmit={handleSubmit(loginEvent)}>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="email"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="E-mail"
                      isInvalid={errors.username}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="Пароль"
                      isInvalid={errors.password}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <Button
                  type="submit"
                  variant="primary"
                  className={style.button}
                  disabled={registerFormValid}
                >
                  Войти
                </Button>
              </Form>

              <div className={style.footer}>
                {/* <LinkButton link="/" className={style.footer_link}>*/}
                {/*  Не помню пароль*/}
                {/* </LinkButton>*/}
                <LinkButton link="/registration" className={style.footer_link}>
                  Регистрация
                </LinkButton>
                {/* <LinkButton link="/" className={style.footer_link}>*/}
                {/*  Помощь*/}
                {/* </LinkButton>*/}
              </div>
            </div>

            {/* <div className={style.down_link}>*/}
            {/*  Войти через*/}
            {/*  <LinkButton link="/"> Портал Госуслуг</LinkButton>*/}
            {/* </div>*/}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
