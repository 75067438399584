import { ISubmenuProps, ISubmenuProps2, ITypeMenuItem } from "../types";

/**
 * Константы пунктов главного меню:
 */
const calculatorPatch = "https://pay.kyrgyzpost.kg";

export const navigationBarItems: ITypeMenuItem[] = [
  { href: "#", text: "Отправить" },
  { href: "#", text: "Получить" },
  { href: "/payments", text: "Платежи" },
  { href: "#", text: "Услуги в отделениях" },
  { href: "#", text: "Онлайн-сервисы" },
];

export const navigationBarItems2: ISubmenuProps2[] = [
  // {
  //   key: "services",
  //   title: "Услуги",
  // },
  {
    key: "about",
    title: "О нас",
    submenu: [
      { title: "Руководство", path: "/about/guide" },
      { title: "Структура", path: "/about/structure" },
      { title: "Филиалы", path: "/about/branches" },
      { title: "Сотрудничество", path: "/about/cooperation" },
      { title: "Партнеры", path: "/about/partners" },
      // { title: "Вакансии", path: "/" },
      { title: "Реквизиты", path: "/about/requisites" },
    ],
  },
  {
    key: "payment",
    title: "Платежи",
    path: "/payments",
  },
  {
    key: "tracking",
    title: "Трекинг",
    path: "/",
  },
  {
    key: "postServices",
    title: "Почтовые услуги",
    submenu: [
      {
        title: "Прием и выдача простых, заказных, ценных писем, бандеролей",
        path: "/services/delivery-of-letters",
      },
      {
        title: "Прием и выдача мелких пакетов",
        path: "/services/package-delivery",
      },
      {
        title: "Прием и выдача посылок",
        path: "/services/delivery-parcels",
      },
      {
        title:
          "Прием и выдача внутренней ускоренной почты (Экспресс отправлений)",
        path: "/services/expedited-mail",
      },
      {
        title: "Прием и выдача международной ускоренной почты (EMS)",
        path: "/services/international-expedited-mail",
      },
    ],
  },
  {
    key: "financialServices",
    title: "Финансовые услуги",
    submenu: [
      {
        title: "Прием и выдача электронных денежных переводов",
        path: "/services/money-transfers",
      },
      {
        title: "Реализация и бронирование авиаперевозок",
        path: "/services/air-transportation",
      },
      {
        title: "Прием платежей",
        path: "/services/accepting-payments",
      },
      {
        title: "Перевод с карты в наличные",
        path: "/services/transfer-from-card-to-cash",
      },
    ],
  },
  {
    key: "subscribe",
    title: "Подписка",
    path: "/services/subscription",
  },
  // {
  //   key: "tariffs",
  //   title: "Тарифы",
  //   submenu: [
  //     {
  //       title: "Почтовые услуги",
  //       path: "",
  //     },
  //     {
  //       title: "Финансоые услуги",
  //       path: "",
  //     },
  //   ],
  // },
  {
    key: "fromCardToCash",
    title: "Переводы с карты в наличные",
    path: "/services/transfer-from-card-to-cash",
  },
  {
    key: "calculator",
    title: "Калькулятор",
    submenu: [
      {
        title: "Отправление внутри республики",
        href: `${calculatorPatch}/calculator1.html`,
        path: "",
      },
      {
        title: "Отправления по СНГ и в дальнее зарубежье",
        href: `${calculatorPatch}/calculator2.html`,
        path: "",
      },
      {
        title: "Отправка международных посылок",
        href: `${calculatorPatch}/calculator3.html`,
        path: "",
      },
      {
        title: "Отправления международной ускоренной почты (МУП/ EMS)",
        href: `${calculatorPatch}/calculator4.html`,
        path: "",
      },
      {
        title: "Отправка посылок внутри Кыргызстана",
        href: `${calculatorPatch}/calculator5.html`,
        path: "",
      },
    ],
  },
  {
    key: "postalCodes",
    title: "Почтовые индексы",
    sideLink: "http://index.kyrgyzpost.kg/",
  },
];

/**
 * Константы пунктов подменю сервисы: (Финансовые услуги):
 */

const submenuServicesFinancialServices: ISubmenuProps = [
  { title: "Финансовые услуги", link: "", text: "" },
  {
    link: "/services/money-transfers",
    text: "Прием и выдача электронных денежных переводов",
  },
  {
    link: "/services/air-transportation",
    text: "Реализация и бронирование авиаперевозок",
  },
  { link: "/services/accepting-payments", text: "Прием платежей" },
  {
    link: "/services/transfer-from-card-to-cash",
    text: "Перевод с карты в наличные",
  },
];

/**
 * Константы пунктов подменю сервисы: (Почтовые услуги):
 */

const submenuServicesPostalServices: ISubmenuProps = [
  { title: "Почтовые услуги", link: "", text: "" },
  {
    link: "/services/delivery-of-letters",
    text: "Прием и выдача простых, заказных, ценных писем, бандеролей",
  },
  { link: "/services/package-delivery", text: "Прием и выдача мелких пакетов" },
  { link: "/services/delivery-parcels", text: "Прием и выдача посылок" },
  {
    link: "/services/expedited-mail",
    text: "Прием и выдача внутренней ускоренной почты (Экспресс отправлений)",
  },
  {
    link: "/services/international-expedited-mail",
    text: "Прием и выдача международной ускоренной почты (EMS)",
  },
];

/**
 * Константы пунктов подменю сервисы: (Калькулятор услуг):
 */

const submenuServicesCalculatorServices: ISubmenuProps = [
  { title: "Калькулятор услуг", link: "", text: "" },
  {
    href: `${calculatorPatch}/calculator1.html`,
    text: "Отправление внутри республики",
    link: "",
  },
  {
    href: `${calculatorPatch}/calculator2.html`,
    text: "Отправления по СНГ и в дальнее зарубежье",
    link: "",
  },
  {
    href: `${calculatorPatch}/calculator3.html`,
    text: "Отправка международных посылок",
    link: "",
  },
  {
    href: `${calculatorPatch}/calculator4.html`,
    text: "Отправления международной ускоренной почты (МУП/ EMS)",
    link: "",
  },
  {
    href: `${calculatorPatch}/calculator5.html`,
    text: "Отправка посылок внутри Кыргызстана",
    link: "",
  },
];

/**
 * Константы пунктов подменю сервисы: (Прочее):
 */

const submenuServicesOther: ISubmenuProps = [
  { title: "Прочие услуги", link: "", text: "" },
  { link: "/services/subscription", text: "Подписка" },
  { href: "http://index.kyrgyzpost.kg", text: "Почтовые индексы", link: "" },
];

export const submenuServicesListLinks = [
  submenuServicesFinancialServices,
  submenuServicesPostalServices,
  submenuServicesCalculatorServices,
  submenuServicesOther,
];
