import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useClassNames } from "../../../utils/hooks";
import styles from "./_base_button.module.scss";

interface IBaseButton {
  children: React.ReactNode;
  buttonType?: string;
  className?: string;
  disabled?: boolean;
  link?: string;
}

/**
 * Атом кнопки. Принимает на вход:
 *
 * @param {React.ReactNode} children - дочерние элементы
 * @param {string} buttonType - стиль оформления кноки (primary/secondary)
 * @param {string} className - внешний класс для стилизации
 * @param {string} link - ссылка на другой роут
 * @param {boolean} disabled - будет ли заблокирована кнопка
 * @returns {JSX.Element}
 */

export const BaseButton: React.FC<IBaseButton> = ({
  children,
  buttonType = "primary",
  className = "",
  disabled = false,
  link,
}) => {
  const buttonClasses = useClassNames({
    [styles.primary_button]: buttonType === "primary",
    [styles.secondary_button]: buttonType === "secondary",
    [className]: true,
  });

  return link ? (
    <NavLink to={link} className={`${buttonClasses} ${className}`}>
      {children}
    </NavLink>
  ) : (
    <Button
      type="submit"
      className={`${buttonClasses} ${className}`}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
