import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "../../store/actions";

// Todo Egor: Добавить типы и описание
export const useActionsHook = () => {
  const dispatch = useDispatch();
  return bindActionCreators(Actions, dispatch);
};
