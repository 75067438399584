import {
  IProfile,
  IProfilePaymentProps,
  ProfileActionTypes,
} from "../../types";

interface IInitialState {
  paymentHistory: IProfilePaymentProps[];
  paymentHistoryRequest: boolean;
}

const initialState: IInitialState = {
  paymentHistory: [],
  paymentHistoryRequest: false,
};

export const profile = (
  state = initialState,
  action: IProfile
): IInitialState => {
  switch (action.type) {
    case ProfileActionTypes.GET_PROFILE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentHistory: action.payload,
      };
    case ProfileActionTypes.GET_PROFILE_PAYMENT_REQUEST:
      return {
        ...state,
        paymentHistoryRequest: action.payload,
      };
    default:
      return state;
  }
};
