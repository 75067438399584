import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "../../_about.module.scss";

/**
 * Фрагмент с информацией о реквизитах, для страницы О нас.
 */
export const Requisites: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Реквизиты</div>
        <div className={styles.paragraph}>ГП “Кыргыз почтасы” при МЦР КР</div>
        <div className={styles.paragraph}>
          720001, Кыргызская Республика, <br />
          г. Бишкек, пр, Чуй 227 <br />
          ГП “Кыргыз почтасы” при МЦР КР <br />
          КОД ОКПО 20382073 <br />
          Иин:01504199410099 <br />
          ОАО “ Айыл Банк” <br />
          БИК: 135001 <br />
          р/счет: 1350100024028647 <br />
          УККН 999
        </div>

        <br />
        <br />
        <br />
        <br />
      </Col>
    </Row>
  </Container>
);
