import axios from "axios";
import { Dispatch } from "redux";

import { apiBaseUrl } from "../../../constants";
import { ISetLogin, LoginActionTypes } from "../../../types";
import { interceptor } from "../../../utils";

const setLogin = (payload: {
  token: string;
  refreshToken: string;
}): ISetLogin => ({
  type: LoginActionTypes.GET_LOGIN_SUCCESS,
  payload,
});

export const postLoginAction = (
  props: {
    username: string;
    password: string;
  },
  nextStep?: () => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const { status, data } = await axios({
        method: "POST",
        url: `${apiBaseUrl}/public/api/login`,
        data: JSON.stringify(props),
      });

      if (status === 200) {
        dispatch(setLogin(data));

        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);

        if (nextStep) nextStep();
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const postRefreshTokenAction = (refreshToken: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const { status, data } = await interceptor({
        method: "POST",
        url: "/private/api/token/refresh",
        data: JSON.stringify({
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (status === 200) {
        dispatch(
          setLogin({
            token: data.token,
            refreshToken: data.refreshToken,
          })
        );

        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getMyProfile = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const { status, data } = await interceptor.get(
        "/private/api/profile/main"
      );

      if (status === 200) {
        console.log(status, data);
      }
    } catch (error) {
      console.log(error);
    }
  };
};
