import { Dispatch } from "redux";

import {
  IProfilePaymentProps,
  ISetProfilePayment,
  ISetProfilePaymentRequest,
  ProfileActionTypes,
} from "../../../types";
import { interceptor } from "../../../utils";

const setProfilePayment = (
  payload: IProfilePaymentProps[]
): ISetProfilePayment => ({
  type: ProfileActionTypes.GET_PROFILE_PAYMENT_SUCCESS,
  payload,
});

const setProfilePaymentRequest = (
  inRequest: boolean
): ISetProfilePaymentRequest => ({
  type: ProfileActionTypes.GET_PROFILE_PAYMENT_REQUEST,
  payload: inRequest,
});

/**
 * Отправляет запрос на получения истории платежей
 */
export const getProfilePaymentAction = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setProfilePaymentRequest(true));
    try {
      const {
        status,
        data: { payments },
      } = await interceptor.get("/private/api/profile/payment");

      if (status === 200) {
        dispatch(setProfilePayment(payments));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setProfilePaymentRequest(false));
  };
};
