import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_services_page.module.scss';
import { ArbitraryList } from '../ArbitraryList';

/**
 * Страниц услуг (Прием и выдача посылок).
 */

export const DeliveryParcels: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием и выдача посылок</div>
        <div className={styles.small_title}>Посылки</div>
        <div className={styles.paragraph}>
          <span>В посылках пересылаются:</span>
        </div>
        <div className={styles.paragraph}>
          Разного рода предметы культурно-бытового обихода и производственного назначения, продукты питания(кроме
          скоропортящихся продуктов — мяса, рыбы, битой птицы, сливочного масла, колбасных изделий и др.), печатные
          издания, растения, пчелы, медикаменты, медицинские препараты и т. д.
        </div>
        <div className={styles.paragraph}>
          <span>Принимаются весом:</span>
        </div>
        <div className={styles.paragraph}>
          а) с делимым вложением — 20 кг. <br/>
          б) с неделимым вложением — 30 кг.
        </div>
        <div className={styles.paragraph}>
          <span>Размеры в упаковке:</span>
        </div>
        <ArbitraryList list={[
          'максимальные 50х50х40;',
          'минимальные (кроме посылок с пчелами ) 10 см в одном измерении и не менее — 5 см в каждом из остальных' +
          ' измерений.'
        ]}/>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
