import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_about.module.scss';

/**
 * Фрагмент с информацией по сотрудничеству, для страницы О нас.
 */

export const Cooperation: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Международное сотрудничество</div>
        <div className={styles.paragraph}>
          Основным направлением международной деятельности Государственного предприятия «Кыргыз почтасы» является
          членство во Всемирном Почтовом союзе (ВПС), Кооперативе EMS, Региональном содружестве в области связи (РСС).
        </div>
        <div className={styles.title}>Информация о проекте «Развитие финансового сектора»</div>
        <div className={styles.paragraph}>
          Государственное предприятие «Кыргыз почтасы» активно проводит реализацию Компонента 2. «Расширение финансовых
          услуг через сеть филиалов ГП «Кыргыз почтасы», проекта Всемирного Банка «Развитие финансового сектора»
          (грант №H760 и кредит №5067) (далее — Проект).
        </div>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
