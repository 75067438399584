import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { log } from "util";

import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import style from "../HomePage/fragments/_news.module.scss";

/**
 * Страница новости
 */
export const ArticlePage: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { getArticle } = useActionsHook();
  const { article, articleRequest } = useTypedSelectorHook(
    ({ articles }) => articles
  );

  useEffect(() => {
    getArticle(id);
  }, [id]);

  function createMarkup(): { __html: string } {
    if (!article || articleRequest) return { __html: "" };
    return { __html: article.content };
  }

  if (!article || articleRequest) return null;

  return (
    <Fragment>
      <Helmet>
        <link
          href="https://pay2.kyrgyzpost.kg/control/assets/css/tinymce.css"
          rel="stylesheet"
        />
      </Helmet>
      <Container className={style.wrapper}>
        <Row>
          <Col xxl={12}>
            <div
              style={{ overflow: "hidden" }}
              className="mce-content-body"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
