import { ILocalizationProps } from '../_global.types';

export enum PaymentCategoriesActionTypes {
  GET_PAYMENT_CATEGORIES_SUCCESS = 'GET_PAYMENT_CATEGORIES_SUCCESS',
  GET_PAYMENT_CATEGORIES_REQUEST = 'GET_PAYMENT_CATEGORIES_REQUEST'
}

export enum PaymentCategoryActionTypes {
  GET_PAYMENT_CATEGORY_SUCCESS = 'GET_PAYMENT_CATEGORY_SUCCESS',
  GET_PAYMENT_CATEGORY_REQUEST = 'GET_PAYMENT_CATEGORY_REQUEST'
}

export enum PaymentServiceActionTypes {
  GET_PAYMENT_SERVICE_SUCCESS = 'GET_PAYMENT_SERVICE_SUCCESS',
  GET_PAYMENT_SERVICE_REQUEST = 'GET_PAYMENT_SERVICE_REQUEST',
  GET_COMMISSION_PAYMENT_SUCCESS = 'GET_COMMISSION_PAYMENT_SUCCESS',
  GET_COMMISSION_PAYMENT_REQUEST = 'GET_COMMISSION_PAYMENT_REQUEST',
  GET_VALIDATE_SUCCESS = 'GET_VALIDATE_SUCCESS',
  GET_VALIDATE_REQUEST = 'GET_VALIDATE_REQUEST',
  GET_TO_PAY_SUCCESS = 'GET_TO_PAY_SUCCESS',
  GET_TO_PAY_REQUEST = 'GET_TO_PAY_REQUEST',
}


export interface ICategoryProps {
  id: number;
  name: ILocalizationProps;
  description: ILocalizationProps;
  img: string;
}

export interface IPaymentCategory {
  serviceCategory: ICategoryProps;
  services: ICategoryProps[];
}

export interface IPaymentCategoriesState {
  categories: ICategoryProps[] | null;
  categoriesRequest: boolean;
  category: IPaymentCategory | null;
  categoryRequest: boolean;
  paymentService: IPaymentService | null;
  paymentServiceRequest: boolean;
  paymentCommission: ICommissionProps | null;
  paymentCommissionRequest: boolean;
  paymentDetails: IValidateProps | null;
  paymentDetailsRequest: boolean;
  toPayRequest: boolean;
}

export interface IServiceProps {
  id: string;
  img: string;
  name: ILocalizationProps;
  description: ILocalizationProps;
  maxPaymentAmount: number;
  maxlengthAccount: string;
  minPaymentAmount: number;
}

export interface ICommissionProps {
  commission: number,
  commissionAmount: number,
  commissionType: string,
  commissionTypeInfo: string,
  totalAmount: number,
  totalPayable: number
}

export interface IToPaymentProps {
  account: string;
  amount: string;
  comment: string;
  email: string;
  cardType: string;
  jwtToken: string;
}

export interface IPaymentService {
  service: IServiceProps;
  serviceCategory: ICategoryProps;
}

export interface ISetPaymentsCategories {
  type: PaymentCategoriesActionTypes.GET_PAYMENT_CATEGORIES_SUCCESS;
  payload: ICategoryProps[];
}

export interface ISetPaymentsCategoriesRequest {
  type: PaymentCategoriesActionTypes.GET_PAYMENT_CATEGORIES_REQUEST;
  payload: boolean;
}

export interface ISetPaymentCategory {
  type: PaymentCategoryActionTypes.GET_PAYMENT_CATEGORY_SUCCESS;
  payload: IPaymentCategory;
}

export interface ISetPaymentCategoryRequest {
  type: PaymentCategoryActionTypes.GET_PAYMENT_CATEGORY_REQUEST;
  payload: boolean;
}

export interface ISetPaymentServices {
  type: PaymentServiceActionTypes.GET_PAYMENT_SERVICE_SUCCESS;
  payload: IPaymentService;
}

export interface ISetPaymentServicesRequest {
  type: PaymentServiceActionTypes.GET_PAYMENT_SERVICE_REQUEST;
  payload: boolean;
}

export interface ISetCommissionPayment {
  type: PaymentServiceActionTypes.GET_COMMISSION_PAYMENT_SUCCESS;
  payload: ICommissionProps;
}

export interface ISetCommissionPaymentRequest {
  type: PaymentServiceActionTypes.GET_COMMISSION_PAYMENT_REQUEST;
  payload: boolean;
}

// свойства котороые приходя при валидации при нажатии на кнопку продолжить
export interface IValidateProps {
  data: {
    details: string
  };
  token: string;
}

export interface ISetValidate {
  type: PaymentServiceActionTypes.GET_VALIDATE_SUCCESS;
  payload: IValidateProps;
}

export interface ISetValidateRequest {
  type: PaymentServiceActionTypes.GET_VALIDATE_REQUEST;
  payload: boolean;
}

export interface ISetToPay {
  type: PaymentServiceActionTypes.GET_TO_PAY_SUCCESS;
  payload: IToPaymentProps;
}

export interface ISetToPayRequest {
  type: PaymentServiceActionTypes.GET_TO_PAY_REQUEST;
  payload: boolean;
}

export type IActionPaymentCategories =
  ISetPaymentCategory
  | ISetPaymentCategoryRequest
  | ISetPaymentsCategories
  | ISetPaymentsCategoriesRequest
  | ISetPaymentServices
  | ISetPaymentServicesRequest
  | ISetCommissionPayment
  | ISetCommissionPaymentRequest
  | ISetValidateRequest
  | ISetValidate
  | ISetToPayRequest;
