import {
  AchievementsPage,
  InformationForMassMedia,
  MediaGallery,
  NewsPage,
  Questions,
} from "./fragments";

import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

/**
 * Страница Пресс центр.
 */

export const PressCenterPage: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { push } = useHistory();

  switch (id) {
    case "achievements":
      return <AchievementsPage />;
    case "news":
      return <NewsPage />;
    case "information":
      return <InformationForMassMedia />;
    case "mediagallery":
      return <MediaGallery />;
    case "questions":
      return <Questions />;
    default:
      push("/");
      return null;
  }
};
