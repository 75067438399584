/**
 * Константы пунктов для списка: (Как отправить перевод?):
 */

const servicesPageListSendingTransfer: string[] = [
  'Нужно обратиться в любое отделение ОАО «Кыргыз почтасы» по месту жительства;',
  'Назвать страну, город, сумму перевода и вид перевода;',
  'Предъявить паспорт;',
  'Заполнить Заявление на перевод, внести деньги и оплатить комиссию.',
];

/**
 * Константы пунктов для списка: (Как получить перевод?):
 */

const servicesPageListGetTransfer: string[] = [
  'Почтальон доставит Вам на дом Извещение о получении перевода;',
  'В почтовом отделении Вы предъявите Извещение о получении перевода и документ, удостоверяющий личность;',
  'Заполните бланк на получение денег;',
  'Получите перевод;',
  'При желании почтальон доставит перевод на дом.',
];

/**
 * Константы пунктов для списка: (Особенности системы денежных переводов ОАО «Кыргыз почтасы»:):
 */

const servicesPageListFeaturesMoneyTransfers: string[] = [
  'География: обширная филиальная сеть ОАО «Кыргыз почтасы» — 920 филиалов и отделений связи по всей республике',
  'Выгода. Низкая комиссия за перевод – от 20 сом, при этом комиссия уплачивается только отправителем денежного перевода.',
  'Оперативность. Скорость доставки денежных переводов – 2 минуты.',
  'Максимальная скорость проведения денежного перевода — перевод становится доступным к получению в режиме on-line;',
  'Удобство. Извещение и доставка денежного перевода на дом.',
  'Надежность. ОАО «Кыргыз почтасы» гарантирует безопасность денежных средств',
  'Простота. Для отправки или получения денежного перевода достаточно иметь при себе документ, удостоверяющий личность',
  'Быстро — деньги можно получить уже через 15-20 минут после их отправки.',
];

/**
 * Константы списков для страницы услуги
 */

export const servicesPageArbitraryLists: { title: string, list: string[] }[] = [{
  title: 'Как отправить перевод?',
  list: servicesPageListSendingTransfer
}, {
  title: 'Как получить перевод?',
  list: servicesPageListGetTransfer
}, {
  title: 'Особенности системы денежных переводов ОАО «Кыргыз почтасы»:',
  list: servicesPageListFeaturesMoneyTransfers
}];

/**
 * Константы ссылки, для пункта (Прием денежных переводов...)
 */

export const servicesPageMoneyTransfersLinks: {title: string; href: string;}[] = [
  {title: 'fiu.gov.kg', href: 'https://www.fiu.gov.kg/'},
  {title: 'Национальный', href: 'https://fiu.gov.kg/user/login/'},
  {title: 'Международный', href: 'https://fiu.gov.kg/sked/2'},
];

/**
 * Константы для таблицы (Обмен международными денежными переводами...)
 */

export const servicesPageTableInternationalTransfers: { td1: string; td2: string; }[] = [
  { td1: 'Россия', td2: 'из России 100000 руб., из Кыргызстана 100000 рублей (или эквивалент в сомах)' },
  { td1: 'Казахстан', td2: '1000 USD' },
  { td1: 'Украина', td2: 'из Украины 1800 USD, из Кыргызстана 1000 USD' },
  { td1: 'Белоруссия', td2: '500 EUR' },
  { td1: 'Молдова', td2: '1000 EUR' },
  { td1: 'Узбекистан', td2: '1000 USD' },
  { td1: 'Таджикистан', td2: '250 USD' },
];

/**
 * Константы для таблицы (Особенности системы денежных переводов...)
 */

export const servicesPageTableMoneyTransferSystems: { td1: string; td2: string; td3: string; td4: string; td5: string; td6: string; }[] = [{
  td1: 'до 1000 сом включительно',
  td2: '20 сом',
  td3: 'до 1000 сом включительно',
  td4: '20 сом',
  td5: 'до 1000 включительно',
  td6: '50 сом'
}, {
  td1: 'от 1000 до 3000 сом',
  td2: '2,2 %',
  td3: 'от 1000 до 10000 сом',
  td4: '30 сом',
  td5: 'свыше 1000 до 10000 сом',
  td6: '4%'
}, {
  td1: 'от 3000 до 6000 сом',
  td2: '1,7 %',
  td3: 'от 10000 до 30000 сом',
  td4: '40 сом',
  td5: 'свыше 10000 до 20000 сом',
  td6: '3%'
}, {
  td1: 'от 6000 до 10000 сом',
  td2: '1,2 %',
  td3: 'от 30000 до 50000 сом',
  td4: '50 сом',
  td5: 'свыше 20000 до 50000 сом',
  td6: '2,5%'
}, {
  td1: 'от 10000 до 20000 сом',
  td2: '0,7 %',
  td3: '—',
  td4: '—',
  td5: 'свыше 50000 сом',
  td6: '2%'
}, {
  td1: 'от 20000 до 50000 сом',
  td2: '0,5 %',
  td3: '—',
  td4: '—',
  td5: '—',
  td6: '—'
}];

/**
 * Константы для списка (Реализация и бронирование авиаперевозок)
 */

export const servicesPageListBookingAirTransportation: string[] = [
  'г. Бишкек пр.Чуй, 96 главпочтамт',
  'с. Беловодское ул. Ленина, 4',
  'с. Сокулук ул. Фрунзе, б/н',
  'пгт. Кемин ул. Ленина, 4',
  'г. Талас ул. Сарыгулова, 28',
  'с. Боконбаево ул. Атахана, 68',
  'с. Кызылсуу ул. Манаса, 83',
  'г. Чолпон-Ата ул. Советская, 122',
  'г. Нарын ул. Токтосунова, 24',
  'с. Кочкор ул. Орозбекова, 128',
  'с. Кербен ул. Комсомольская, 2',
  'г. Кара-Куль ул. Ленина, 1 А',
  'с. Массы ул. Турдумбетова, 35',
  'с. Сузак ул. Каримбердиева, 1',
  'г. Таш-Кумыр ул. Ш. Сыдыкова, 11',
  'пгт. Токтогул ул. Ленина, 5',
];

/**
 * Список констант (электроэнергию), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageListElectricity: string[] = [
  'ОАО «Северэлектро»;',
  'ОАО «Востокэлектро»;',
  'ОАО «Ошэлектро»;',
];

/**
 * Список констант (газ), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageListGas: string[] = [];

/**
 * Список констант (холодную воду), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageColdWater: string[] = [
  'многоэтажные дома;',
  'частный сектор;',
];

/**
 * Список констант (горячую воду и отопление;), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageHotWater: string[] = [];

/**
 * Список констант (лифт:), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageElevator: string[] = [
  'кыргызлифт;',
  'БишкекГорЛифт;',
];

/**
 * Список констант (вывоз мусора:), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageGarbageCollection: string[] = [
  'МП «Тазалык»;',
  'Комтранском;',
];

/**
 * Список констант (домофон:), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageIntercom: string[] = [
  'центр обслуживания домофонов;',
  'мастер-домофон;',
  'столичный домофон;',
  'цифрал безопасность;',
  'кыргыз дом сервис;',
];

/**
 * Список констант (коммунальные услуги:), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageUtilities: string[] = [
  'ТСЖ «Люси»;',
  'ОсОО «Айбол»;',
  'ЖК «Акылдаш»;',
  'ЖК «Багуу»;',
  'ЖК Орбита и т.д.;',
];

/**
 * Список констант (услуги эфирно-кабельного телевидения:), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageTelevision: string[] = [
  'ОсОО «Ала ТВ»',
  'ОсОО «Долон ТВ»',
  'ОсОО «Смарт ТВ»',
  'ОсОО «НаТВ»',
];

/**
 * Список констант (Интернет провайдеры:), для общего списка (Прием платежей за коммунальные услуги:)
 */

const servicesPageInternet: string[] = [
  'ОсОО «Aknet»',
  'ОсОО «Exnet»',
  'ОсОО «Мегалайн»',
];

/**
 * Список констант (Прием платежей за коммунальные услуги:)
 */

export const servicesPagePaymentsPopulation: {title: string; list: string[];}[] = [
  {title: '— электроэнергию:', list: servicesPageListElectricity},
  {title: '— газ:', list: servicesPageListGas},
  {title: '— холодную воду:', list: servicesPageColdWater},
  {title: '— горячую воду и отопление:', list: servicesPageHotWater},
  {title: '— лифт:', list: servicesPageElevator},
  {title: '— вывоз мусора:', list: servicesPageGarbageCollection},
  {title: '— домофон:', list: servicesPageIntercom},
  {title: '— коммунальные услуги:', list: servicesPageUtilities},
  {title: '— услуги эфирно-кабельного телевидения:', list: servicesPageTelevision},
  {title: '— Интернет провайдеры:', list: servicesPageInternet},
];

/**
 * Список констант (За услуги сотовой связи:), для общего списка (Прием платежей за услуги сотовых операторов:)
 */

const servicesPageCellularCommunication: string[] = [
  'ЗАО «Альфа Телеком» (Мегаком);',
  'ООО «Sky Mobile»;',
  'ОсОО «НУР Телеком» («О»);',
];

/**
 * Список констант (Прием платежей за услуги сотовых операторов:)
 */

export const servicesPageListCellularCommunication: {title: string; list: string[];}[] = [
  {title: '— За услуги сотовой связи:', list: servicesPageCellularCommunication}
];

/**
 * Список констант (Штрафы за:), для общего списка (Прием платежей за административные правонарушения:)
 */

const servicesPageAdministrativeOffenses: string[] = [
  'Административные правонарушения правил дорожного движения (ПДД);',
  'Фото-видео фиксация;',
];

/**
 * Список констант (Прием платежей за административные правонарушения:)
 */

export const servicesPageListAdministrativeOffenses: {title: string; list: string[];}[] = [
  {title: '— Штрафы за:', list: servicesPageAdministrativeOffenses}
];

/**
 * Список констант (услуги Банка:), для общего списка (услуги Банка:)
 */

const servicesPageBankServices: string[] = [
  'пополнение и обналичивание электронного кошелька «ЭЛСОМ» (КИКБ);',
  'Погашение и выдача кредита ОАО «Айыл Банк»;',
];

/**
 * Список констант (услуги Банка:), для общего списка (услуги Банка:)
 */

export const servicesPageListBankServices: {title: string; list: string[];}[] = [
  {title: '', list: servicesPageBankServices }
];

/**
 * Константы ссылки, для пункта (ВНИМАНИЮ РАБОТНИКОВ ПОЧТОВОЙ СВЯЗИ)
 */

export const servicesPageSubscribeDownloadDocks: {title: string; href: string;}[] = [
  {title: 'СКАЧАТЬ РЕСПУБЛИКАНСКИЙ КАТАЛОГ ГАЗЕТЫ 1-2019', href: 'https://drive.google.com/file/d/1rzkwzT56muqTp_Ldb9VFf1iN9HCefeUo/view'},
  {title: 'CКАЧАТЬ РЕСПУБЛИКАНСКИЙ КАТАЛОГ ЖУРНАЛЫ 1-2019', href: 'https://drive.google.com/file/d/1qimXJC4Cykb2ADIHPuh6CDhcobCVK708/view'},
  {title: 'CКАЧАТЬ 2019-1 КАТАЛОГ АРЗИ', href: 'https://drive.google.com/file/d/101xvgNyk-P1KUZZruey-HGyD3Y3cthOW/view'},
  {title: 'CКАЧАТЬ КАТАЛОГ ИНФОРМ НАУКА 1-2019', href: 'https://drive.google.com/file/d/170_dBz-ttc_E3AbWdZdU4bHgoqJnsUum/view'},
  {title: 'CКАЧАТЬ РОС ПЕЧАТЬ 1-2019', href: 'https://kyrgyzpost.kg/?page_id=80#'},
];
