import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import style from "../HomePage/fragments/_news.module.scss";

/**
 * Страница с динамическим контентом
 */
export const DynamicPage: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { getItemMainMenu } = useActionsHook();
  const { itemMainMenu, itemMainMenuRequest } = useTypedSelectorHook(
    ({ mainMenu }) => mainMenu
  );

  useEffect(() => {
    getItemMainMenu(id);
  }, [id]);

  function createMarkup(): { __html: string } {
    if (!itemMainMenu || itemMainMenuRequest) return { __html: "" };
    return { __html: itemMainMenu.content };
  }

  if (!itemMainMenu || itemMainMenuRequest) return null;

  return (
    <Fragment>
      <Helmet>
        <link
          href="https://pay2.kyrgyzpost.kg/control/assets/css/tinymce.css"
          rel="stylesheet"
        />
      </Helmet>
      <Container className={style.wrapper}>
        <Row>
          <Col xxl={12}>
            <div
              style={{ overflow: "hidden" }}
              className="mce-content-body"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
