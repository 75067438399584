import React, { useEffect, useState } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";

import { CommonShell } from "./components";
import {
  AboutPage,
  ArticlePage,
  ArticlesPage,
  AuthorizationPage,
  DynamicPage,
  HomePage,
  ParcelTrackingPage,
  PaymentHistoryPage,
  PaymentPage,
  PaymentServicePage,
  PaymentsPage,
  PaymentStatus,
  PressCenterPage,
  RegistrationPage,
  ServicesPage,
} from "./pages";
import { useActionsHook, useTypedSelectorHook } from "./utils/hooks";

// import { useActionsHook, useTypedSelectorHook } from "./utils/hooks";

function App(): JSX.Element {
  const { postRefreshTokenAction, getMainMenu } = useActionsHook();
  const { token } = useTypedSelectorHook(({ user }) => user);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [token]);

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (isLogin && refreshToken) {
      const timer = setInterval(() => {
        postRefreshTokenAction(refreshToken);
      }, 5 * 60 * 1000);
      return () => clearInterval(timer);
    }
  }, [isLogin]);

  useEffect(() => {
    getMainMenu();
  }, []);

  return (
    <HashRouter>
      <CommonShell>
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/payment/:id" component={PaymentPage} />
          <Route path="/dynamic/:id" component={DynamicPage} />
          <Route path="/status-payment" component={PaymentStatus} />
          <Route path="/payments" component={PaymentsPage} />
          <Route path="/payment-service/:id" component={PaymentServicePage} />
          <Route path="/payment-history" component={PaymentHistoryPage} />
          <Route path="/registration/" component={RegistrationPage} />
          <Route path="/authorization/" component={AuthorizationPage} />
          {/* <Route path="/profile" component={ProfilePage} />*/}
          <Route path="/services/:id" component={ServicesPage} />
          <Route path="/about/:id" component={AboutPage} />
          <Route path="/parcel-tracking" component={ParcelTrackingPage} />
          <Route path="/articles" component={ArticlesPage} />
          <Route path="/news/:id" component={ArticlePage} />
          <Route path="/presscenter/:id" component={PressCenterPage} />
          <Redirect to="/" />
        </Switch>
      </CommonShell>
    </HashRouter>
  );
}

export default App;
