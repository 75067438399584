import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { News } from "../../components";
import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import style from "../HomePage/fragments/_news.module.scss";

/**
 * Страница всех новостей
 */
export const ArticlesPage: React.FC = () => {
  const { getArticles } = useActionsHook();
  const { articles, articlesRequest } = useTypedSelectorHook(
    ({ articles }) => articles
  );

  useEffect(() => {
    getArticles();
  }, []);

  if (!articles || articlesRequest) return null;

  return (
    <Container className={style.wrapper}>
      <Row>
        <Col xxl={12}>
          <News
            articles={articles}
            articlesRequest={articlesRequest}
            showLink={false}
          />
        </Col>
      </Row>
    </Container>
  );
};
