export enum ProfileActionTypes {
  GET_PROFILE_PAYMENT_SUCCESS = "GET_PROFILE_PAYMENT_SUCCESS",
  GET_PROFILE_PAYMENT_REQUEST = "GET_PROFILE_PAYMENT_REQUEST",
}

export interface IProfilePaymentProps {
  id: string;
  transaction_id: string;
  account: string;
  amount: number;
  total_amount: number;
  total_payable: number;
  commission: number;
  commission_amount: number;
  commission_type: number;
  currency: string;
  comment: string;
  email: string;
  status: string;
  status_name: string;
  source_type: string;
  service_name: string;
  processed_at: any;
  created_at: string;
}

export interface ISetProfilePayment {
  type: ProfileActionTypes.GET_PROFILE_PAYMENT_SUCCESS;
  payload: IProfilePaymentProps[];
}

export interface ISetProfilePaymentRequest {
  type: ProfileActionTypes.GET_PROFILE_PAYMENT_REQUEST;
  payload: boolean;
}

export type IProfile = ISetProfilePayment | ISetProfilePaymentRequest;
