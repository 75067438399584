import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_services_page.module.scss';

/**
 * Страниц услуг (Прием и выдача простых, заказных, ценных писем, бандеролей).
 */

export const DeliveryLetters: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием и выдача простых, заказных, ценных писем, бандеролей</div>
        <div className={styles.small_title}>Простые, заказные письма.</div>
        <div className={styles.paragraph}>
          В простых и заказных письмах принимаются разного рода письменные сообщения, фотографические карточки, деловые
          бумаги, художественные открытки, схемы и другая аналогичная бумажная продукция.
        </div>
        <div className={styles.paragraph}>
          Весом до 2 кг.<br/>
          Размер упаковок: 114х162 мм.;<br/>
          162х229 мм.;<br/>
          229х324 мм.;
        </div>
        <div className={styles.small_title}>Простые и заказные бандероли.</div>
        <div className={styles.paragraph}>
          В простых и заказных бандеролях рекомендуется пересылать малоценные печатные издания (бланки, брошюры,
          плакаты, книги и т.п.) деловые бумаги, рукописи, альбомы и другие предметы не представляющие ценности
          для отправителя.
        </div>
        <div className={styles.paragraph}>
          Весом до 2 кг. <br/>
          За исключением бандеролей: <br/>
          с семенами – 1 кг. <br/>
          с вложением одной книги – 3 кг. <br/>
          с бумагой и книгами, имеющими выпуклые знаки для слепых – 5 кг.
        </div>
        <div className={styles.paragraph}>Размеры упаковок:</div>
        <div className={styles.paragraph}>
          максимальные 35 см. в одном из трех измерений (длина, ширина, высота). Сумма трех измерений не должна
          превышать 65 см.
        </div>
        <div className={styles.paragraph}>для бандеролей свернутых трубкой, 70 см. в длину и 15 см. в диаметре.</div>
        <div className={styles.small_title}>Ценные письма.</div>
        <div className={styles.paragraph}>В ценных письмах пересылаются:</div>
        <div className={styles.paragraph}>
          документы и ценные бумаги (дипломы, паспорта, свидетельство о рождении и браке и другие денежные документы)
          имеющих для отправителя и получателя ценность.
        </div>
        <div className={styles.paragraph}>
          Весом до 2 кг. <br/>
          Размеры упаковок: 114х162 мм. <br/>
          162х229 мм. <br/>
          229х324 мм.
        </div>
        <div className={styles.paragraph}>
          Ценные письма от граждан принимаются только в открытом виде с проверкой вложения. <br/>
          Открытые ценные письма и бандероли принимаются с описью вложения.
        </div>
        <div className={styles.small_title}>Ценные бандероли.</div>
        <div className={styles.paragraph}>В ценных бандеролях пересылаются:</div>
        <div className={styles.paragraph}>
          предметы культурно-бытового и производственного назначения, печатные издания, деловые бумаги, кондитерские
          изделия и другие продукты питания длительного хранения, сохранность которых будет обеспечена в упаковке,
          предусмотренной для данного вида почтовых отправлений.
        </div>
        <div className={styles.paragraph}>
          Весом до 1 кг.
        </div>
        <div className={styles.paragraph}>
          Размеры в упаковке: минимальные 10см. в одном измерении и не менее 5 см. в другом измерении (при любой
          минимальной толщине), а в свернутых трубкой – 10 см. в длину и 5 см. в диаметре.
        </div>
        <div className={styles.paragraph}>
          Ценные бандероли от граждан принимаются только в открытом виде с проверкой вложения.
        </div>
        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
