import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useTypedSelectorHook } from "../../utils/hooks";
import { BannerLearnMore, Footer, NavigationBar } from "../molecules";

/**
 * Общий шаблон для всех страниц
 *
 * @param {React.ReactNode} children - вложенные компоненты
 * @returns {React.FC}
 */

export const CommonShell: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const { pathname } = useLocation();
  const [isShowBanner, setIsShowBanner] = useState<boolean>(false);
  const { mainMenu, mainMenuRequest } = useTypedSelectorHook(
    ({ mainMenu }) => mainMenu
  );

  const pathId: string | undefined = pathname.split("/")[2];
  const paths: string[] = [""]; // ['/', '/payments', `/payment/${pathId}`]

  useEffect(() => {
    setIsShowBanner(() => paths.some((path) => path === pathname));
  }, [pathname]);

  return (
    <React.Fragment>
      <NavigationBar mainMenu={mainMenu} />
      {isShowBanner && <BannerLearnMore />}
      <div style={{ minHeight: "100vh" }}>{children}</div>
      <Footer />
    </React.Fragment>
  );
};
