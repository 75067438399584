import React from "react";
import { Col, Container } from "react-bootstrap";

import { ParcelTracking } from "../../components";
import { useTypedSelectorHook } from "../../utils/hooks";
import style from "./_parcel_tracking_page.module.scss";

export const ParcelTrackingPage: React.FC = () => {
  const { tracking }: { tracking: any } = useTypedSelectorHook(
    ({ trackingEmails }) => trackingEmails
  );

  return (
    <Container>
      <Col lg={24}>
        <ParcelTracking />
        <div className={style.tracking_wrapper}>
          <div className={style.tracking} />
          {tracking.map((item, index) => {
            const date = new Date(item.date);

            return (
              <div className={style.item} key={index}>
                {/* <div className={style.item_date}>{`${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`}</div>*/}
                <div className={style.item_date}>
                  {date.toLocaleString("ru")}
                </div>
                <div className={style.item_title}>{item.EventName}</div>
              </div>
            );
          })}
        </div>
      </Col>
    </Container>
  );
};
