import axios from "axios";
import { Dispatch } from "redux";
import { number } from "yup";

import { apiBaseUrl } from "../../../constants";
import {
  ICommissionProps,
  IPaymentService,
  ISetCommissionPayment,
  ISetCommissionPaymentRequest,
  ISetPaymentServices,
  ISetPaymentServicesRequest,
  ISetToPayRequest,
  ISetValidate,
  ISetValidateRequest,
  IToPaymentProps,
  IValidateProps,
  PaymentServiceActionTypes,
} from "../../../types";
import { interceptor } from "../../../utils";

/**
 * Задает значение информации по оплате услуги
 *
 * @param {IPaymentService} payload - категории оплаты.
 * @returns {ISetPaymentServices}
 */

const setPaymentService = (payload: IPaymentService): ISetPaymentServices => ({
  type: PaymentServiceActionTypes.GET_PAYMENT_SERVICE_SUCCESS,
  payload: payload,
});

/**
 * Задает состояние запроса категории оплаты по ID
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetPaymentCategoryRequest}
 */

const setPaymentServicesRequest = (inRequest): ISetPaymentServicesRequest => ({
  type: PaymentServiceActionTypes.GET_PAYMENT_SERVICE_REQUEST,
  payload: inRequest,
});

/**
 * Выполняет запрос на получение информации по услуге оплаты
 *
 * @param {string} categoryId - ID категории
 */
export const getPaymentService = (categoryId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setPaymentServicesRequest(true));
    try {
      const { status, data } = await axios.get(
        `${apiBaseUrl}/public/api/service/${categoryId}/one`
      );

      if (status === 200) {
        dispatch(
          setPaymentService({
            service: data.service,
            serviceCategory: data.serviceCategory,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setPaymentServicesRequest(false));
  };
};

/**
 * Задает состояние запроса о результате рассчета коммисии
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetCommissionPaymentRequest}
 */

const setCommissionPaymentRequest = (
  inRequest: boolean
): ISetCommissionPaymentRequest => ({
  type: PaymentServiceActionTypes.GET_COMMISSION_PAYMENT_REQUEST,
  payload: inRequest,
});

/**
 * Задает значение результата рассчетов о коммисии
 *
 * @param {ICommissionProps} payload - результаты рассчета коммисии.
 * @returns {ISetCommissionPayment}
 */

const setCommissionPayment = (
  payload: ICommissionProps
): ISetCommissionPayment => ({
  type: PaymentServiceActionTypes.GET_COMMISSION_PAYMENT_SUCCESS,
  payload: payload,
});

/**
 * Выполняет запрос на получение рассчетов о коммисии оплаты
 *
 * @param {string} serviceId - ID категории
 * @param {number} amount - Сумма пополнения
 */
// Todo Egor: Параметры экщенов переписать на объекты, ВЕЗДЕ !!!
// Todo Egor: Method POST а не GET !!!
export const getCommissionPayment = (serviceId: string, amount: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    setCommissionPaymentRequest(true);
    try {
      const {
        status,
        data: { data },
      } = await axios({
        method: "POST",
        url: `${apiBaseUrl}/public/api/service/${serviceId}/calculator-commission`,
        data: JSON.stringify({ amount: amount }),
      });

      if (status === 200) {
        dispatch(setCommissionPayment(data));
      }
    } catch (error) {
      console.log(error);
    }
    setCommissionPaymentRequest(false);
  };
};

// Todo Egor: Здесь сетеры для статуса валидации по нажатию на кнопку, продолжить

const setValidate = (payload: IValidateProps): ISetValidate => ({
  type: PaymentServiceActionTypes.GET_VALIDATE_SUCCESS,
  payload: payload,
});

const setValidateRequest = (inRequest: boolean): ISetValidateRequest => ({
  type: PaymentServiceActionTypes.GET_VALIDATE_REQUEST,
  payload: inRequest,
});

/**
 * Выполняет запрос на проверку наличия пользователя
 *
 * @param {string} amount - сумма
 * @param {string} callback - функция для выполнения после успешного запроса
 * @param {string} account - номер телефона
 * @param {string} serviceId - ID услуги которую нужно провалидировать
 * @param {string} recaptchaToken - Токен из reCaptcha
 * @param {number} amount - Сумма пополнения
 */
export const postValidateRequisite = ({
  callback,
  recaptchaToken,
  serviceId,
  account,
  amount,
}: {
  serviceId: string;
  amount: number;
  account: string;
  callback?: () => void;
  recaptchaToken: string;
}) => {
  return async (dispatch: Dispatch): Promise<void> => {
    setValidateRequest(true);
    try {
      const { status, data } = await axios({
        method: "POST",
        url: `${apiBaseUrl}/public/api/service/${serviceId}/validate-requisite`,
        data: JSON.stringify({
          recaptchaToken: recaptchaToken,
          account: account,
          amount: amount,
        }),
      });

      if (status === 200 && data.status) {
        // Смотреть на статусы прошла оплата или нет
        dispatch(setValidate(data));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setValidateRequest(false);
  };
};

const setToPay = (inRequest: boolean): ISetToPayRequest => ({
  type: PaymentServiceActionTypes.GET_TO_PAY_REQUEST,
  payload: inRequest,
});

export const postToPay = (paymentData: IToPaymentProps, serviceId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setToPay(true));
    try {
      const { status, data } = await axios({
        method: "POST",
        url: `${apiBaseUrl}/public/api/service/${serviceId}/pay-service`,
        data: JSON.stringify(paymentData),
      });

      if (status === 200 && data.status) {
        // Выполняет переход на страницу оплаты
        window.location.href = data.url;
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setToPay(false));
  };
};

/**
 * Выполняет запрос на скачивание чека об оплате
 *
 * @param {string} paymentId - ID категории
 * @param {string} hash - Сумма пополнения
 */

export const getDownloadReceipt = (paymentId: string, hash: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/public/api/payment/download-payment-receipt/${paymentId}?hash=${hash}`,
        { responseType: "blob" }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "payment-receipt.pdf";
        link.click();
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Выполняет запрос на проверку наличия пользователя если пользователь авторизовался
 *
 * @param {string} amount - сумма
 * @param {string} callback - функция для выполнения после успешного запроса
 * @param {string} account - номер телефона
 * @param {string} serviceId - ID услуги которую нужно провалидировать
 * @param {number} amount - Сумма пополнения
 */
export const postValidateRequisitePrivate = ({
  callback,
  serviceId,
  account,
  amount,
}: {
  serviceId: string;
  amount: number;
  account: string;
  callback?: () => void;
}) => {
  return async (dispatch: Dispatch): Promise<void> => {
    setValidateRequest(true);
    try {
      const { status, data } = await interceptor({
        method: "POST",
        url: `/private/api/service/${serviceId}/validate-requisite`,
        data: JSON.stringify({
          account: account,
          amount: amount,
        }),
      });

      if (status === 200 && data.status) {
        // Смотреть на статусы прошла оплата или нет
        dispatch(setValidate(data));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setValidateRequest(false);
  };
};

export const postToPayPrivate = (
  paymentData: {
    account: string;
    amount: string;
    comment: string;
    email: string;
    cardType: string;
  },
  serviceId: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setToPay(true));
    try {
      const { status, data } = await interceptor({
        method: "POST",
        url: `/private/api/service/${serviceId}/pay-service`,
        data: JSON.stringify(paymentData),
      });

      if (status === 200 && data.status) {
        // Выполняет переход на страницу оплаты
        window.location.href = data.url;
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setToPay(false));
  };
};
