import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { servicesPageListBookingAirTransportation } from '../../../../constants';
import styles from '../../_services_page.module.scss';

/**
 * Страниц услуг (Реализация и бронирование авиаперевозок).
 */

export const AirTransportation: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Реализация и бронирование авиаперевозок</div>
        <div className={styles.paragraph}>
          В кассах филиалов ГП «Кыргыз почтасы» можно приобрести билеты — авиа, железнодорожные и автобусные.
        </div>
        <br/>
        {servicesPageListBookingAirTransportation.map((item, index) => (
          <div className={styles.paragraph} key={item}>{index + 1} {item}</div>
        ))}
        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
