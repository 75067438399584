import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import imgMoney from "../../../assets/images/home_page/img-money-services.png";
import { BaseButton } from "../../../components";
import styles from "./_services.module.scss";

/**
 * Фрагмент: "Сервисы и услуги".
 */

export const Services: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col xxl={12}>
            <div className={styles.title}>Сервисы и услуги</div>
          </Col>

          <Col xxl={8}>
            <div className={styles.left_content}>
              <img src={imgMoney} alt="money" />
              <div>
                <div className={styles.left_content_title}>
                  {/* Прием <br />*/}
                  {/* платежей*/}
                </div>
                <div className={styles.left_content_paragraph}>
                  {/* Коммунальные услуги без <br />*/}
                  {/* комиссий*/}
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={4}>
            <div className={styles.right_content}>
              <div className={styles.right_content_title}>
                Прием <br />
                платежей
                {/* Электронные <br/>*/}
                {/* заказные письма*/}
              </div>
              <div className={styles.right_content_paragraph}>
                Коммунальные услуги без <br />
                комиссий
                {/* Отправить и получить <br />*/}
                {/* можно онлайн*/}
              </div>
            </div>
          </Col>
          <Col>
            <div className={styles.footer}>
              <div className={styles.paragraph}>
                Пройдите далее для выбора подходящего для вас услуг
              </div>

              <BaseButton
                buttonType="primary"
                link="/payments"
                className={styles.button}
              >
                Больше услуг
              </BaseButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
