import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_services_page.module.scss';
import { ArbitraryList } from '../ArbitraryList';

/**
 * Страниц услуг (Прием и выдача мелких пакетов).
 */

export const PackageDelivery: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием и выдача мелких пакетов</div>
        <div className={styles.small_title}>Мелкие пакеты</div>
        <div className={styles.paragraph}>В мелких пакетах пересылаются:</div>
        <ArbitraryList list={['мелкие небьющиеся предметы подарочного характера.']}/>
        <br/>
        <div className={styles.paragraph}>
          Мелкие пакеты принимаются в открытом виде, на каждый мелкий пакет заполняется таможенная декларация ФС — 23
          в количестве экземпляров, соответствующему Списку стран и Руководству.
        </div>
        <div className={styles.paragraph}>Принимаются весом до — 2 кг.</div>
        <div className={styles.paragraph}>
          Размеры в упаковке: для рулонов сумма длины и двойного диаметра не более 104 см. Наибольшее измерение — 90 см,
          минимальное 14х19 см, для рулонов сумма длины и двойного диаметра 17 см, наибольшее измерение — не более 10 см.
        </div>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
