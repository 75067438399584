import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { aboutUsPageBranches, aboutUsPageStructure } from 'src/constants';

import imgStructure from '../../../../assets/images/about/structure.jpg';
import { ArbitraryList } from '../../../ServicesPage/fragments';
import styles from '../../_about.module.scss';

/**
 * Фрагмент с информацией о структре, для страницы О нас.
 */

export const Structure: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Структура Центрального аппарата ГП «Кыргыз почтасы» при МЦР при ПКР</div>
        <ArbitraryList list={[
          'Генеральный директор',
          'Первый заместитель генерального директора',
          'Заместитель генерального директора',
          'Заместитель генерального директора'
        ]}/>

        <br/>

        {aboutUsPageStructure.map((item) => (
          <div className={styles.paragraph}>
            {item}
          </div>
        ))}

        <br/>

        <div className={styles.paragraph}>
          <span>Филиалы</span>
        </div>

        {aboutUsPageBranches.map((item) => (
          <div className={styles.paragraph}>
            {item}
          </div>
        ))}

        <br/>

        <img src={imgStructure} alt="Structure" width="100%"/>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
