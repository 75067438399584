import 'bootstrap/scss/bootstrap.scss';
import './assets/styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { _store } from './store';

ReactDOM.render(
  <Provider store={_store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);
