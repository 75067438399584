import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_about.module.scss';

/**
 * Фрагмент с информацией о Филиалах, для страницы О нас.
 */

export const Branches: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Филиалы</div>
        <div className={styles.paragraph}>
          ГП «Кыргыз почтасы» при ГРС при ПКР имеет самую разветвленную сеть филиалов и отделений почтовой связи,
          распределенных по всей территории Кыргызской Республики и состоящую из 921 объектов: филиал «Центр
          международного почтового обмена и логистики» (ЦМПОЛ), филиал «Бишкекский почтамт», филиал «Ошский почтамт»,
          46 филиала районного и городского уровня, 872 отделений почтовой связи в составе территориальных филиалов.
        </div>

        <br/><br/><br/><br/><br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
