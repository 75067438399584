import axios from 'axios';
import { Dispatch } from 'redux';

import { apiBaseUrl } from '../../../constants';
import {
  IPaymentCategory,
  ISetPaymentCategory,
  ISetPaymentCategoryRequest,
  PaymentCategoryActionTypes
} from '../../../types';

/**
 * Задает значение категории оплаты по ID
 *
 * @param {IPaymentCategory} payload - категории оплаты.
 * @returns {ISetPaymentCategory}
 */
const setPaymentCategoryAction = (payload: IPaymentCategory): ISetPaymentCategory => ({
  type: PaymentCategoryActionTypes.GET_PAYMENT_CATEGORY_SUCCESS,
  payload: payload
});

/**
 * Задает состояние запроса категории оплаты по ID
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetPaymentCategoryRequest}
 */
const setPaymentCategoryRequest = (inRequest: boolean): ISetPaymentCategoryRequest => ({
  type: PaymentCategoryActionTypes.GET_PAYMENT_CATEGORY_REQUEST,
  payload: inRequest
});

/**
 * Выполняет запрос на получение категории оплаты по ID
 *
 * @param {string} categoryId - ID категории оплаты
 */
export const getPaymentCategoryAction = (categoryId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setPaymentCategoryRequest(true));
    try {
      const {
        status,
        data
      } = await axios.get(`${apiBaseUrl}/public/api/service-category/${categoryId}/one`);

      if (status === 200) {
        dispatch(setPaymentCategoryAction({
          serviceCategory: data.serviceCategory,
          services: data.services
        }));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setPaymentCategoryRequest(false));
  };
};
