import { Col, Container, Row } from "react-bootstrap";

import React from "react";
import styles from "../../_press_center.module.scss";

/**
 * Фрагмент c новостями, для страницы Пресс центр.
 */

export const NewsPage: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>
          Хочешь получить бесплатную путевку в Швейцарию??? Тогда это для
          тебя!!!
        </div>
        <div className={styles.paragraph}>
          У тебя сегодня есть возможность воспользоваться моментом и выиграть
          бесплатную путевку в Швейцарию, а также получить множество подарков!!!
          Спеши, время ограниченно, только до 7 апреля 2022 года.
          Государственное предприятие «Кыргыз почтасы» совместно Министерством
          образования и науки Кыргызской Республики проводит 51-ый международный
          молодежный конкурс сочинений эпистолярного жанра на тему: «Напишите
          письмо влиятельному человеку о том, почему и как […]
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Col>
    </Row>
  </Container>
);
