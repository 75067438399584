import React, { FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import { LinkButton } from "src/components";

import { IArticle } from "../../../types";
// import { newsList } from "../../../constants";
import styles from "./_news_carousel.module.scss";

interface INewsCarousel {
  articles: IArticle[];
}

/**
 * Фрагмент: "Карусель новостей".
 */
export const NewsCarousel: FC<INewsCarousel> = ({ articles }) => {
  if (!articles) return null;

  return (
    <Carousel fade style={{ height: "600px" }}>
      {articles.map(({ img, title, description, id }, index) => (
        <Carousel.Item key={`${title}${index}`}>
          <img
            width="100%"
            height="600px"
            className="d-block w-100"
            src={`https://pay2.kyrgyzpost.kg/uploads/article/${img}`}
            alt="First slide"
            style={{ objectFit: "cover" }}
          />
          <Carousel.Caption>
            <LinkButton link={`/news/${id}`}>
              <div className={styles.description_content}>
                <h3 className={styles.slider_title}>{title}</h3>
                <p className={styles.slider_description}>{description}</p>
              </div>
            </LinkButton>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
