import React, { useEffect, VFC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";

import { useActionsHook, useTypedSelectorHook } from "../../../utils/hooks";
import { SearchForm } from "../forms";
import style from "./_parcel_tracking.module.scss";

interface IParcelTracking {
  redirect?: boolean;
}

/**
 * Фрагмент: "Трекинг посылок".
 */
export const ParcelTracking: VFC<IParcelTracking> = ({ redirect = false }) => {
  const { getTrackingEmailAction } = useActionsHook();
  const {
    tracking,
    trackingRequest,
  }: { tracking: any; trackingRequest: boolean } = useTypedSelectorHook(
    ({ trackingEmails }) => trackingEmails
  );
  // const [isShowNotParcel, setIsShowNotParcel] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (tracking.length !== 0 && redirect) {
      // setIsShowNotParcel(false);
      if (trackingRequest) history.push("/parcel-tracking");
    }
    // else {
    //   setIsShowNotParcel(true);
    // }
  }, [tracking]);

  // useEffect(() => {
  //   if (tracking.length === 0) getTrackingEmailAction("RF727913178SG");
  // }, []);

  function sendingEvent(trackNumber): void {
    getTrackingEmailAction(trackNumber);
  }

  return (
    <div className={style.wrapper}>
      <Container>
        <Row>
          <Col lg={6}>
            <div className={style.title}>Трекинг посылок</div>
          </Col>
          <Col lg={6}>
            <div className={style.content}>
              <SearchForm
                sendingEvent={(trackNumber) => sendingEvent(trackNumber)}
                className={style.search_form}
                placeholder="Введите трек-номер"
              />
              <div className={style.example}>пример: RF727913178SG</div>
            </div>
          </Col>
          {/* {isShowNotParcel}*/}
        </Row>
      </Container>
    </div>
  );
};
