interface ILinkListProperties {
  href: string;
  text: string;
  sideLink?: string;
}

/**
 * Константы пунктов меню в футере:
 */

export const aboutUsLinks: ILinkListProperties[] = [
  { href: "/about/guide", text: "Руководство" },
  { href: "/about/structure", text: "Структура" },
  { href: "/about/branches", text: "Филиалы" },
  { href: "/about/cooperation", text: "Сотрудничество" },
  { href: "/about/partners", text: "Партнеры" },
  // { href: '/', text: 'Вакансии' },
  { href: "/about/requisites", text: "Реквизиты" },
  {
    href: "/",
    sideLink: "https://kyrgyzpost.kg/sp.pdf",
    text: 'Стратегический план ГП "КП"',
  },
  // { href: '/', text: 'Тендеры' }
];

export const servicesLinks: ILinkListProperties[] = [
  // { href: "/", text: "Почтовые услуги" },
  // { href: "/", text: "Финансовые услуги" },
  {
    href: "/services/transfer-from-card-to-cash",
    text: "Перевод с карты в наличные",
  },
  {
    href: "/",
    sideLink: "http://calc.kyrgyzpost.kg/",
    text: "Калькулятор налога",
  },
  // { href: "/", text: "Отследить посылку" },
  { href: "/services/accepting-payments", text: "Прием платежей" },
  { href: "/services/subscription", text: "Подписка" },
];

export const pressCenterLinks: ILinkListProperties[] = [
  {
    text: "Новости",
    sideLink: "https://pay.kyrgyzpost.kg/?page_id=10",
    href: "",
  },
  {
    href: "/presscenter/achievements",
    text: "Наши достижения",
  },
  {
    href: "/presscenter/information",
    text: "Информация для СМИ",
  },
  {
    href: "/presscenter/mediagallery",
    text: "Медиагалерея",
  },
  {
    href: "/presscenter/questions",
    text: "Вопросы",
  },
  {
    href: "/",
    sideLink: "http://index.kyrgyzpost.kg/",
    text: "Почтовые индексы",
  },
  {
    href: "/",
    sideLink: "https://pay.kyrgyzpost.kg/?page_id=7569",
    text: "Книга отзывов и предложений",
  },
];

export const pricingPlans: ILinkListProperties[] = [
  { href: "/", text: "Почтовых услуг" },
  { href: "/", text: "Финансовых услуг" },
];

export const copyright = 'Copyright 2022 © ГП "Кыргыз Почтасы"';
