import axios from "axios";
import { Dispatch } from "redux";

import { apiBaseUrl } from "../../../constants";
import {
  ICategoryProps,
  ISetPaymentsCategories,
  ISetPaymentsCategoriesRequest,
  PaymentCategoriesActionTypes,
} from "../../../types";

/**
 * Задает значение категорий оплаты
 *
 * @param {ICategoryProps[]} payload - категории оплаты.
 * @returns {ISetPaymentsCategories}
 */
const setPaymentsCategories = (
  payload: ICategoryProps[]
): ISetPaymentsCategories => ({
  type: PaymentCategoriesActionTypes.GET_PAYMENT_CATEGORIES_SUCCESS,
  payload,
});

/**
 * Задает состояние запроса категорий оплаты по тэгу
 *
 * @param {boolean} inRequest - выполняется ли запрос.
 * @returns {ISetPaymentsCategoriesRequest}
 */
const setPaymentsCategoriesRequest = (
  inRequest: boolean
): ISetPaymentsCategoriesRequest => ({
  type: PaymentCategoriesActionTypes.GET_PAYMENT_CATEGORIES_REQUEST,
  payload: inRequest,
});

/**
 *  Выполняет запрос на получение категорий оплаты
 */
export const getPaymentCategoriesAction = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setPaymentsCategoriesRequest(true));
    try {
      const {
        status,
        data: { serviceCategories },
      } = await axios.get(`${apiBaseUrl}/public/api/service-category/list`);

      if (status === 200) {
        dispatch(setPaymentsCategories(serviceCategories));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setPaymentsCategoriesRequest(false));
  };
};
