import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { CardCategory, LinkButton } from "../../components";
import { Preloader } from "../../components/layouts/_Preloader.layout";
import { baseUrl } from "../../constants";
import { ICategoryProps } from "../../types";
import { useActionsHook, useTypedSelectorHook } from "../../utils/hooks";
import styles from "./_payment_page.module.scss";

/**
 * Страница услуги категории платежа.
 */

export const PaymentPage: React.FC = () => {
  const { getPaymentCategoryAction } = useActionsHook();
  const { category, categoryRequest } = useTypedSelectorHook(
    ({ paymentCategories }) => paymentCategories
  );
  const { id: paramId } = useParams<{ id: string }>();
  const [categoryList, setCategoryList] = useState<ICategoryProps[]>([]);

  useEffect(() => {
    if (typeof paramId !== "undefined") {
      getPaymentCategoryAction(paramId);
    }
  }, []);

  useEffect(() => {
    if (category) {
      // rename to services
      setCategoryList(category.services);
    }
  }, [category]);

  return (
    <div className={styles.services}>
      <Container>
        <Row>
          <Col xxl={12}>
            <div className={styles.title}>
              {category?.serviceCategory.name.ru}
            </div>
          </Col>
        </Row>
        <Row>
          <Preloader isLoad={categoryRequest}>
            {categoryList.map((category) => (
              <Col xxl={3} key={category.id}>
                <LinkButton link={`/payment-service/${category.id}`}>
                  <CardCategory
                    title={category.name.ru}
                    description={category.description.ru}
                    image={`${baseUrl}${category.img}`}
                    className={styles.card}
                  />
                </LinkButton>
              </Col>
            ))}
          </Preloader>
        </Row>
      </Container>
    </div>
  );
};
