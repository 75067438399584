import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { Branches, Cooperation,Guide, Partners, Requisites, Structure } from './fragments';

/**
 * Страница О нас.
 */

export const AboutPage: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { push } = useHistory();

  switch (id) {
    case 'guide':
      return <Guide/>;
    case 'structure':
      return <Structure/>;
    case 'branches':
      return <Branches/>;
    case 'requisites':
      return <Requisites/>;
    case 'partners':
      return <Partners/>;
    case 'cooperation':
      return <Cooperation/>;
    default:
      push('/');
      return null;
  }
};
