import { Col, Container, Row } from "react-bootstrap";

import React from "react";
import styles from "../../_press_center.module.scss";

/**
 * Фрагмент информация для СМИ, для страницы Пресс центр.
 */

export const InformationForMassMedia: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>
          Почтовики поддержали Всемирный день чистоты 08.02.2019!
        </div>
        <div className={styles.paragraph}>
          В поддержку Всемирного дня чистоты 15 сентября кыргызстанцы вышли на
          массовый Суботник. В свою очередь, «Кыргыз почтасы» не остался в
          стороне. В экоакции приняло участие 48 филиалов «Кыргыз почтасы»,
          более 1000 сотрудников вышли на уборку мусора по всему Кыргызстану.
          Дорогие кыргызстанцы, мы сами создаем общество и страну в которой
          живем. Давайте начнем всецело уважать и ценить природу, давайте начнем
          с элементарных вещей: не бросать мусор, соблюдать культуру и чистоту
          не только в дни акций, но и в простые будни. Наш Кыргызстан – это наше
          достояние, о котором должен заботиться каждый гражданин и каждый день.
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Col>
    </Row>
  </Container>
);
