import React from "react";

import style from "./_icon_button.module.scss";

interface IIconButton {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
}

/**
 * Атом кнопка с иконкой. Принимает на вход:
 *
 * @param {React.ReactNode} children - дочерние элементы
 * @param {boolean} disabled - статус включения кнопки
 * @param {string} className - внешний класс для стилизации кнопки
 * @param {string} iconClassName - внешний класс для стилизации иконки
 * @returns {JSX.Element}
 */

export const IconButton: React.FC<IIconButton> = ({
  children,
  disabled = true,
  className = "",
  iconClassName,
}) => (
  <button
    type="submit"
    className={`${style.icon_button} ${className}`}
    disabled={disabled}
  >
    <div className={`${style.icon} ${iconClassName}`}>{children}</div>
  </button>
);
