import React, { Fragment } from "react";
import { LinkButton } from "src/components";

import styles from "./_submenu_item.module.scss";

/**
 * Компонент списка для подменю.
 */
export const SubmenuItem: React.FC<{
  list: any;
}> = ({ list }) => (
  <div className={styles.list}>
    {list.map(({ name, link, is_static, id }) =>
      is_static ? (
        <Fragment>
          <a
            href={link}
            className={styles.link}
            children={name}
            target="_blank"
            rel="noreferrer"
          />
          {/* {!href ? (*/}
          {/* <LinkButton link={path} className={styles.link}>*/}
          {/*  {title}*/}
          {/* </LinkButton>*/}
          {/* ) : (*/}
          {/*  <a*/}
          {/*    href={href}*/}
          {/*    className={styles.link}*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*  >*/}
          {/*    {title}*/}
          {/*  </a>*/}
          {/* )}*/}
        </Fragment>
      ) : (
        <LinkButton link={`/dynamic/${id}`} className={styles.link}>
          {name}
        </LinkButton>
      )
    )}
  </div>
);
