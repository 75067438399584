import { ArticlesActionTypes, IArticle, IArticleActions } from "../../types";

interface IInitialState {
  articles: IArticle[] | null;
  articlesRequest: boolean;
  articlesError: string | null;
  article: IArticle | null;
  articleRequest: boolean;
  articleError: string | null;
}

const initialState: IInitialState = {
  articles: null,
  articlesRequest: false,
  articlesError: null,
  article: null,
  articleRequest: false,
  articleError: null,
};

const articlesReducer = (
  state = initialState,
  action: IArticleActions
): IInitialState => {
  switch (action.type) {
    case ArticlesActionTypes.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
      };
    case ArticlesActionTypes.GET_ARTICLES_REQUEST:
      return {
        ...state,
        articlesRequest: action.payload,
      };
    case ArticlesActionTypes.GET_ARTICLES_ERROR:
      return {
        ...state,
        articlesError: action.payload,
      };
    case ArticlesActionTypes.GET_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload,
      };
    case ArticlesActionTypes.GET_ARTICLE_REQUEST:
      return {
        ...state,
        articleRequest: action.payload,
      };
    case ArticlesActionTypes.GET_ARTICLE_ERROR:
      return {
        ...state,
        articleError: action.payload,
      };
    default:
      return state;
  }
};

export { articlesReducer };
