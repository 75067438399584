import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from '../../_services_page.module.scss';

/**
 * Страниц услуг (Прием и выдача внутренней ускоренной почты (Экспресс отправлений)).
 */

export const ExpeditedMail: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>Прием и выдача внутренней ускоренной почты (Экспресс отправлений)</div>
        <div className={styles.small_title}>«ЭКСПРЕСС-ПОЧТА»</div>
        <div className={styles.paragraph}>
          <span>
            ПРЕДЛАГАЕТ УСЛУГИ ПО ПЕРЕСЫЛКЕ ПИСЕМ С ДОКУМЕНТАМИ, А ТАКЖЕ БАНДЕРОЛЕЙ И ПОСЫЛОК ПО КЫРГЫЗСТАНУ
            В КРАТЧАЙШИЕ СРОКИ.
          </span>
        </div>
        <div className={styles.paragraph}>
          <span>
            «МЕЖДУНАРОДНАЯ УСКОРЕННАЯ ПОЧТА»
          </span>
        </div>
        <div className={styles.paragraph}>
          <span>
            ПРЕДЛАГАЕТ УСЛУГИ ПО ПЕРЕСЫЛКЕ ПИСЕМ, А ТАКЖЕ БАНДЕРОЛЕЙ И ПОСЫЛОК ЗА ПРЕДЕЛЫ КЫРГЫЗСТАНА
            В КРАТЧАЙШИЕ СРОКИ.
          </span>
        </div>
        <div className={styles.paragraph}>
          В письмах, бандеролях, посылках, и мелких пакетах запрещается пересылать иностранную валюту и деньги,
          имеющие обращение на территории Кыргызской Республики.
        </div>
        <div className={styles.paragraph}>
          Оценивайте пересылаемое в страховых почтовых отправлениях вложение, согласно его фактической стоимости.
        </div>
        <div className={styles.paragraph}>
          Предприятия почтовой связи несут материальную ответственность за принятые страховые почтовые отправления
          в размере объявленной ценности.
        </div>
        <div className={styles.paragraph}>
          Заявление о розыске почтовых отправлений принимаются от отправителей в течение 6 месяцев со дня,
          следующего за днем подачи отправления.
        </div>
        <div className={styles.paragraph}>
          Основание: Ст.30 Всемирной Почтовой Конвенции.
        </div>

        <br/><br/><br/><br/>
      </Col>
    </Row>
  </Container>
);
