export enum RegistrationActionTypes {
  GET_REGISTRATION_SUCCESS = "GET_REGISTRATION_SUCCESS",
  GET_REGISTRATION_REQUEST = "GET_REGISTRATION_REQUEST",
}

export enum LoginActionTypes {
  GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS",
  GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST",
}

export interface ISetLogin {
  type: LoginActionTypes.GET_LOGIN_SUCCESS;
  payload: {
    token: string;
    refreshToken: string;
  };
}

export interface ISetLoginRequest {
  type: LoginActionTypes.GET_LOGIN_REQUEST;
  payload: boolean;
}

export type IActionUser = ISetLogin | ISetLoginRequest;
