import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import { reCaptchaToken } from "../../constants";
import { useActionsHook } from "../../utils/hooks";
import styles from "../PaymentServicePage/_payment_service_page.module.scss";
import style from "./_index.module.scss";

/**
 * Страница регистарции.
 */

export const RegistrationPage: React.FC = () => {
  const { postRegistrationAction, postLoginAction } = useActionsHook();
  const [reCaptcha, setReCaptcha] = useState("");
  const [registerFormValid, setRegisterFormValid] = useState(true);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    if (reCaptcha) {
      setRegisterFormValid(false);
    }
  }, [reCaptcha]);

  function registrationEvent(data): void {
    postRegistrationAction({ ...data, recaptcha_token: reCaptcha }, () => {
      postLoginAction({
        username: data.email,
        password: data.password,
      });
      history.push("/");
    });
    setRegisterFormValid(true);
  }

  return (
    <Container>
      <Row className="justify-content-xxl-center">
        <Col xxl={8}>
          <div className={style.wrapper}>
            <img
              src={logo}
              alt="logo"
              className={style.logo}
              width={220}
              height={60}
            />

            <div className={style.content}>
              <div className={style.title}>Регистрация</div>

              <Form
                className={style.registration_form}
                onSubmit={handleSubmit(registrationEvent)}
              >
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="email"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="E-mail"
                      isInvalid={errors.email}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="Пароль"
                      isInvalid={errors.password}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="Имя"
                      isInvalid={errors.firstName}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <Controller
                  name="last_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="Фамилия"
                      isInvalid={errors.lastName}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <Controller
                  name="middle_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      className={styles.input}
                      placeholder="Отчество"
                      isInvalid={errors.middleName}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                <br />
                <ReCAPTCHA
                  sitekey={reCaptchaToken}
                  onChange={(value) => setReCaptcha(value)}
                />
                <br />

                <Button
                  type="submit"
                  variant="primary"
                  className={style.button}
                  disabled={registerFormValid}
                >
                  Зарегистрироваться
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
