import React from 'react';

import { Layers } from '../icons';
import styles from './_category.module.scss';

interface ICardCategory {
  title: string;
  description?: string;
  className?: string;
  image: string;
  onClick?: () => void;
}

/**
 * Атом карточка для категорий. Принимает на вход:
 *
 * @param {string} title - Заголовок
 * @param {string} description - Описание
 * @param {string} image - Изображение
 * @param {string} className - Внешний класс
 * @param {string} onClick - Внешнее событие клика
 * @returns {JSX.Element}
 */

export const CardCategory: React.FC<ICardCategory> = ({
  title,
  description,
  image,
  className= '',
  onClick
}) => (
  <div className={`${styles.card} ${className}`} onClick={onClick}>
    <div className={styles.card_content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.paragraph}>{description}</div>
    </div>
    {/*{image ? <img src={image} alt={title} className={styles.icon}/> : <Layers className={styles.icon}/>}*/}
    <Layers className={styles.icon}/>
  </div>
);
