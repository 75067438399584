import { Dispatch } from "redux";

import { IMenu, IMenuActions, MenuActionTypes } from "../../../types";
import { interceptor } from "../../../utils";

/* Сеттеры на получение главного меню */
const setMainMenu = (payload: IMenu): IMenuActions => ({
  type: MenuActionTypes.GET_MENU_SUCCESS,
  payload,
});

const setMainMenuRequest = (payload: boolean): IMenuActions => ({
  type: MenuActionTypes.GET_MENU_REQUEST,
  payload,
});

const setMainMenuError = (payload: string | null): IMenuActions => ({
  type: MenuActionTypes.GET_MENU_ERROR,
  payload,
});

/* Экшен получения главного меню */
const getMainMenu = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setMainMenuRequest(true));
    try {
      const {
        status,
        data: { menuPages },
      } = await interceptor.get("/public/api/menu-page");

      if (status === 200) {
        dispatch(setMainMenu(menuPages));
      }
    } catch (error) {
      dispatch(setMainMenuError(null));
      dispatch(setMainMenuError("Ошибка получения меню"));
      console.log(error);
    }
    dispatch(setMainMenuRequest(false));
  };
};

/* Сеттеры на получение отдельного пункта меню */
const setItemMainMenu = (payload: IMenu): IMenuActions => ({
  type: MenuActionTypes.GET_ITEM_MENU_SUCCESS,
  payload,
});

const setItemMainMenuRequest = (payload: boolean): IMenuActions => ({
  type: MenuActionTypes.GET_ITEM_MENU_REQUEST,
  payload,
});

const setItemMainMenuError = (payload: string | null): IMenuActions => ({
  type: MenuActionTypes.GET_ITEM_MENU_ERROR,
  payload,
});

/* Экшен получения отдельного пункта меню */
const getItemMainMenu = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setItemMainMenuRequest(true));

    console.log(id);

    try {
      const {
        status,
        data: { menuPage },
      } = await interceptor.get(`/public/api/menu-page/${id}`);

      if (status === 200) {
        dispatch(setItemMainMenu(menuPage));
      }
    } catch (error) {
      dispatch(setItemMainMenuError(null));
      dispatch(setItemMainMenuError("Ошибка получения пункта меню"));
      console.log(error);
    }
    dispatch(setItemMainMenuRequest(false));
  };
};

export {
  getItemMainMenu,
  getMainMenu,
  setItemMainMenu,
  setItemMainMenuError,
  setItemMainMenuRequest,
  setMainMenu,
  setMainMenuError,
  setMainMenuRequest,
};
