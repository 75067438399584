import { Col, Container, Row } from "react-bootstrap";

import React from "react";
import styles from "../../_press_center.module.scss";

/**
 * Фрагмент c достижениями, для страницы Пресс центр.
 */

export const AchievementsPage: React.FC = () => (
  <Container>
    <Row>
      <Col xxl={12}>
        <div className={styles.title}>
          Более 5 000 открыток и писем разлетелись по всему миру с III Всемирных
          игр кочевников 08.02.2019!
        </div>
        <div className={styles.paragraph}>
          Более 5 000 открыток и писем разлетелись по всему миру с III Всемирных
          игр кочевников. Кыргыз почтасы открыл два офиса почты на III Всемирных
          играх кочерников, 1-й расположился на ипподроме г. Чолпон-Ата и 2-й в
          этногородке Кырчын. Для работы на Играх кочевников были отобраны
          лучшие сотрудники со знаниями английского, немецкого, турецкого
          языков. С первого по восьмое сентября почтовики с головой погрузилась
          в мир наших предков. В этногородке, наши ребята, знакомили с историей
          и культурой кыргызского народа гостей из разных стран, а также
          рассказывали про почту Кыргызстана. В офисах почты на ипподроме г.
          Чолпон-Ата и в Кырчыне сотрудники «Кыргыз почтасы» помогали всем
          желающим отправить впечатления об Играх кочевников и красоте
          Кыргызстана. Туристы с разных стран скупая марки и открытки
          рассказали, что отправка писем почтой-это что-то трепетное и
          увлекательное, именно поэтому они стали участниками проекта
          «Посткроссинг». Больше всего гости восхищены прекрасными пейзажами
          нашей Родины, традициями и культурой кыргызов, а также увлекательными
          играми кочевников. Так Кыргызстан присоединился ко всемирному проекту
          Посткроссинг (англ. postcrossing) — проект, созданный для возможности
          получения открыток со всего мира. В основе принципа обмена открытками
          лежит единая база всех участников проекта, а также механизм выдачи
          адресов, направленный на то, чтобы разница между отправленными и
          полученными открытками у каждого участника была минимальной. При этом
          в посткроссинге имеет место система непрямого обмена, то есть
          отправляя открытки одним пользователям, участник получает их от
          других. По последним данным, в проекте зарегистрировано более 676 000
          человек из 208 стран мира. Напомним, офис почты на ипподроме
          предоставлял почтовые услуги, продажу марок, уникальных открыток и
          государственные регистрационные услуги, такие как: получение и обмен
          паспорта (ID— карт и общегражданский), получение свидетельства о
          рождении, свидетельства о заключении брака, свидетельства о смерти,
          свидетельства о регистрации установления отцовства, ПИН, прописка и
          выписка, выдача адресных справок, справки о недвижимости и многое
          другое. Осуществлял продажу билетов на закрытие ВИК и информировал
          посетителей о новых проектах почты.
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Col>
    </Row>
  </Container>
);
