import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { BaseButton } from '../../index';
import styles from './_banner_learn_more.module.scss';

/**
 * Банер с информацией о платежах за комуналку.
 */

export const BannerLearnMore: React.FC = () => {
  return (
    <div className={styles.cover}>
      <Container>
        <Row>
          <Col xxl={8}>
            <div className={styles.header}>
              <div className={styles.header_title}>
                Платить за коммуналки <br/> стало ещё легче!
              </div>
              <div className={styles.header_paragraph}>
                Платите Без комиссий за: газ, электричество, телефон, лифт, отопление, <br/>
                тазалык и тд. <br/>
                Следите за расходами и сохраняйте шаблоны оплат.
              </div>
              <BaseButton buttonType="secondary" link="/payments">
                Узнать больше
              </BaseButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
