import {
  ITrackingEmails,
  ITrackingEmailsProps,
  TrackingEmailsActionTypes,
} from "../../types";

interface IInitialState {
  tracking: ITrackingEmailsProps[];
  trackingRequest: boolean;
}

const initialState = {
  tracking: [],
  trackingRequest: false,
};

export const trackingEmails = (
  state = initialState,
  action: ITrackingEmails
): IInitialState => {
  switch (action.type) {
    case TrackingEmailsActionTypes.GET_TRACKING_EMAILS_SUCCESS:
      return {
        ...state,
        tracking: action.payload,
      };
    case TrackingEmailsActionTypes.GET_TRACKING_EMAILS_REQUEST:
      return {
        ...state,
        trackingRequest: action.payload,
      };
    default:
      return state;
  }
};
