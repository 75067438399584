import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Logo from "../../../assets/images/logo.svg";
import { useTypedSelectorHook } from "../../../utils/hooks";
import { Account, LinkButton } from "../../atoms";
import styles from "./_navigation_bar.module.scss";
import { ItemNavigation, SubmenuItem } from "./fragment";

interface INavigationBar {
  mainMenu: any;
}

/**
 * Гланое меню.
 */
export const NavigationBar: React.FC<INavigationBar> = ({ mainMenu }) => {
  const [isShowSubmenu, setIsShowSubmenu] = useState(false);
  const [submenuServicesListLinks, setSubmenuServicesListLinks] = useState<any>(
    []
  );
  const history = useHistory();
  const { token } = useTypedSelectorHook(({ user }) => user);
  const [isLogin, setIsLogin] = useState(false);

  function eventIsShowSubMenu(event, submenu?: any, path?: boolean): void {
    event.preventDefault();
    event.stopPropagation();

    if (!path) {
      setIsShowSubmenu(false);
    } else {
      if (typeof submenu !== "undefined") {
        setSubmenuServicesListLinks([...submenu]);
      }
      setIsShowSubmenu(true);
    }
  }

  document.addEventListener("click", () => {
    if (isShowSubmenu) {
      setIsShowSubmenu(false);
      setSubmenuServicesListLinks([]);
    }
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [token]);

  return (
    <Container fluid className={styles.navbar_container}>
      <Navbar collapseOnSelect expand="lg" className={styles.navbar}>
        <div className={styles.top_bar}>
          <LinkButton link="/" className={styles.logo}>
            <img src={Logo} alt="Kyrgyz Pochtasy" />
          </LinkButton>
          <Navbar>
            <Nav>
              {!isLogin ? (
                <LinkButton link="/authorization" className={styles.link}>
                  <Account width={16} height={16} fill="#1795FF" /> Войти
                </LinkButton>
              ) : (
                <div className={styles.dropdown}>
                  <Account width={16} height={16} fill="#1795FF" />
                  <NavDropdown title="Профиль" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                      <LinkButton link="/payment-history">
                        История платежей
                      </LinkButton>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="#action/3.2"
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        history.push("/");
                        setIsLogin(false);
                      }}
                    >
                      Выйти
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              )}
            </Nav>
          </Navbar>
        </div>
        <div className={styles.bottom_bar}>
          <Navbar>
            <Nav className={styles.menu}>
              {mainMenu.map(({ name, link, children, is_static, id }) => (
                <ItemNavigation
                  children={name}
                  // path={link}
                  sideLink={link}
                  isStatic={!is_static}
                  onClick={(event) => {
                    eventIsShowSubMenu(event, children, children.length > 0);
                  }}
                  key={name}
                  id={id}
                />
              ))}
            </Nav>
          </Navbar>
          {isShowSubmenu && (
            <div className={styles.submenu}>
              <div className={styles.container}>
                <SubmenuItem list={submenuServicesListLinks} />
              </div>
            </div>
          )}
        </div>
      </Navbar>
    </Container>
  );
};
